import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

const DialogFormS24Campaigns = ({
  open,
  onClose,
  onSave,
  userRole,
  userName,
  tempROI_a_value,
  userROI_a_value,
  tempROI_a_value2,
  userROI_a_value2,
  tempProfit,
  userProfit,
  tempBudget,
  userBudget,
  budgetROITips,
  handleValueChange,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          width: "35%",
          height: "60%",
        },
      }}
    >
      <DialogTitle>Edit Rule</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} padding={2}>
          <pre>
            <div>
              IF ROI_a yesterday BETWEEN&nbsp;&nbsp;&nbsp;
              <TextField
                value={userRole === "admin" ? tempROI_a_value : userROI_a_value[userName]}
                onChange={(e) => handleValueChange("ROI_a_value", e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "8%" }}
              />
              &nbsp;%&nbsp;&nbsp;AND&nbsp;&nbsp;&nbsp;
              <TextField
                value={userRole === "admin" ? tempROI_a_value2 : userROI_a_value2[userName]}
                onChange={(e) => handleValueChange("ROI_a_value2", e.target.value)}
                variant="standard"
                size="small"
                style={{ width: "8%" }}
              />
            </div>
            <div>
              AND profit yesterday&nbsp;&gt;=&nbsp;&nbsp;
              <TextField
                value={userRole === "admin" ? tempProfit : userProfit[userName]}
                onChange={(e) => handleValueChange("Profit", e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "8%" }}
              />
              &nbsp;$
            </div>
            <div>
              SET daily budget TO&nbsp;&nbsp;&nbsp;
              <TextField
                value={userRole === "admin" ? tempBudget : userBudget[userName]}
                onChange={(e) => handleValueChange("Budget", e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "8%" }}
              />
              &nbsp;$
            </div>
          </pre>
        </Box>
        <MDBox mb={1} mt={5}>
          <MDTypography variant="body2" color="text">
            Follow these tips to ensure correct ROI/Budget setup
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {budgetROITips}
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button onClick={onSave} startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFormS24Campaigns;

DialogFormS24Campaigns.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  tempROI_a_value: PropTypes.number.isRequired,
  userROI_a_value: PropTypes.object.isRequired,
  tempROI_a_value2: PropTypes.number.isRequired,
  userROI_a_value2: PropTypes.object.isRequired,
  tempProfit: PropTypes.number.isRequired,
  userProfit: PropTypes.object.isRequired,
  tempBudget: PropTypes.number.isRequired,
  userBudget: PropTypes.object.isRequired,
  budgetROITips: PropTypes.node.isRequired,
  handleValueChange: PropTypes.func.isRequired,
};
