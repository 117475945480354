import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import Paper from "@mui/material/Paper";

const extractText = (jsx) => {
  if (typeof jsx === "string") return jsx;
  if (React.isValidElement(jsx)) {
    return React.Children.toArray(jsx.props.children).map(extractText).join("");
  }
  return "";
};

const DataTable = ({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  showHeaders,
  noEndBorder,
}) => {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25", "50"];

  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  // Состояния для пагинации и поиска
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(defaultValue);
  const [sortBy, setSortBy] = useState([]);
  const [search, setSearch] = useState("");
  const handleSearch = (searchTerm) => {
    const filteredRows = table.rows.filter((row) => {
      return Object.keys(row).some((key) => {
        const columnValue = row[key];
        const textValue = extractText(columnValue);
        return textValue.toLowerCase().includes(searchTerm.toLowerCase());
      });
    });
    setFilteredData(filteredRows);
  };

  const [filteredData, setFilteredData] = useState(data);

  const tableInstance = useTable(
    {
      columns,
      data: filteredData,
      initialState: {
        pageIndex,
        pageSize,
        sortBy,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize: setTablePageSize,
    setGlobalFilter,
    state: { globalFilter },
    setSortBy: setTableSortBy,
  } = tableInstance;

  useEffect(() => {
    setTableSortBy(sortBy);
  }, [sortBy]);

  useEffect(() => {
    setTablePageSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    handleSearch(search);
  }, [search, table.rows]);

  const setEntriesPerPage = (value) => {
    setPageSize(value);
    setPageIndex(0);
  };

  const handleSort = (column) => {
    const newSortBy = [{ id: column.id, desc: !column.isSortedDesc }];
    setSortBy(newSortBy);
  };

  return (
    <MDBox display="flex" flexDirection="column" height="100%">
      <Paper sx={{ display: "flex", flexDirection: "column", height: "auto" }}>
        <MDBox p={3} sx={{ flex: "1 1 auto", display: "flex", flexDirection: "column" }}>
          {entriesPerPage || canSearch ? (
            <MDBox display="flex" flexDirection="column" mb={2} sx={{ width: "100%" }}>
              {entriesPerPage && (
                <MDBox display="flex" alignItems="center" mb={1}>
                  <MDInput
                    size="small"
                    select
                    value={pageSize}
                    onChange={(event) => setEntriesPerPage(parseInt(event.target.value, 10))}
                    sx={{ width: "6rem" }}
                  >
                    {entries.map((entry) => (
                      <option key={entry} value={entry}>
                        {entry}
                      </option>
                    ))}
                  </MDInput>
                  <MDTypography variant="caption" color="secondary" sx={{ ml: 1 }}>
                    entries per page
                  </MDTypography>
                </MDBox>
              )}
              {canSearch && (
                <MDBox width="100%" padding="10px">
                  <MDInput
                    placeholder="Search Filter..."
                    value={search}
                    size="small"
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setSearch(currentTarget.value);
                    }}
                    sx={{ width: "100%" }}
                  />
                </MDBox>
              )}
            </MDBox>
          ) : null}
          <TableContainer
            sx={{ flex: "1 1 auto", overflow: "auto", maxHeight: "calc(100vh - 300px)" }}
          >
            <Table {...getTableProps()}>
              {showHeaders && (
                <MDBox component="thead">
                  {headerGroups.map((headerGroup, key) => (
                    <TableRow
                      key={key}
                      {...headerGroup.getHeaderGroupProps()}
                      sx={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "white !important",
                        zIndex: 1,
                      }}
                    >
                      {headerGroup.headers.map((column, idx) => (
                        <DataTableHeadCell
                          key={idx}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          width={column.width ? column.width : "auto"}
                          align={column.align ? column.align : "left"}
                          sorted={
                            sortBy.find((sort) => sort.id === column.id)?.desc ? "desc" : "asc"
                          }
                          onClick={() => !column.disableSortBy && handleSort(column)}
                          disableSortBy={column.disableSortBy}
                        >
                          {column.render("Header")}
                        </DataTableHeadCell>
                      ))}
                    </TableRow>
                  ))}
                </MDBox>
              )}
              <TableBody {...getTableBodyProps()}>
                {page.map((row, key) => {
                  prepareRow(row);
                  return (
                    <TableRow key={key} {...row.getRowProps()}>
                      {row.cells.map((cell, idx) => (
                        <DataTableBodyCell
                          key={idx}
                          noBorder={noEndBorder && page.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          maxHeight={cell.column.maxHeight}
                          height={cell.column.height}
                          isUrl={cell.column.isUrl}
                          urlParam={cell.column.urlParam}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </DataTableBodyCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {pagination && (
            <TablePagination
              rowsPerPageOptions={entries}
              component="div"
              count={filteredData.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              onPageChange={(event, newPage) => {
                setPageIndex(newPage);
                gotoPage(newPage);
              }}
              onRowsPerPageChange={(event) => {
                setEntriesPerPage(parseInt(event.target.value, 10));
                setPageIndex(0);
              }}
              sx={{
                position: "sticky",
                bottom: 0,
                left: 0,
                width: "100%",
                backgroundColor: "white !important",
                padding: "16px",
                boxShadow: "none",
                zIndex: 1,
              }}
            />
          )}
        </MDBox>
      </Paper>
    </MDBox>
  );
};

DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25, 50] },
  canSearch: false,
  showTotalEntries: true,
  pagination: true,
  showHeaders: true,
  noEndBorder: false,
};

DataTable.propTypes = {
  entriesPerPage: PropTypes.shape({
    defaultValue: PropTypes.number,
    entries: PropTypes.arrayOf(PropTypes.number),
  }),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.shape({
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
  }).isRequired,
  pagination: PropTypes.bool,
  showHeaders: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
