import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import AccountCheckboxList from "./data_accounts";
import CampaignCheckboxList from "./data_campaigns";
import { useTableDataAdsetLogs, useFilteredData, adsetLogsTips } from "./adsets_logs";
import DialogFormS24Campaigns from "./s24campaigns_dialog";
import S24Adsets from "./s_24_adsets";
import MDTypography from "../../../components/MDTypography";

function S24Campaigns() {
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "15%" },
      { Header: "condition", accessor: "condition", disableSortBy: true, width: "40%" },
      { Header: "action", accessor: "action", disableSortBy: true, width: "40%" },
      { Header: "EDIT", accessor: "edit", disableSortBy: true, width: "5%" },
    ],
    rows: [],
  });
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [tempROI_a_value, setTempROI_a_value] = useState("");
  const [tempROI_a_value_VLAD, setTempROI_a_value_VLAD] = useState("");
  const [tempROI_a_value_ARTEM, setTempROI_a_value_ARTEM] = useState("");
  const [tempROI_a_value_ALEX, setTempROI_a_value_ALEX] = useState("");
  const [tempROI_a_value2, setTempROI_a_value2] = useState("");
  const [tempROI_a_value2_VLAD, setTempROI_a_value2_VLAD] = useState("");
  const [tempROI_a_value2_ARTEM, setTempROI_a_value2_ARTEM] = useState("");
  const [tempROI_a_value2_ALEX, setTempROI_a_value2_ALEX] = useState("");
  const [tempBudget, setTempBudget] = useState("");
  const [tempBudget_VLAD, setTempBudget_VLAD] = useState("");
  const [tempBudget_ARTEM, setTempBudget_ARTEM] = useState("");
  const [tempBudget_ALEX, setTempBudget_ALEX] = useState("");
  const [tempProfit, setTempProfit] = useState("");
  const [tempProfit_VLAD, setTempProfit_VLAD] = useState("");
  const [tempProfit_ARTEM, setTempProfit_ARTEM] = useState("");
  const [tempProfit_ALEX, setTempProfit_ALEX] = useState("");

  const tableDataAdsetLogs = useTableDataAdsetLogs("s_24");
  const filteredDataLogs = useFilteredData(tableDataAdsetLogs, search);

  const userNames = {
    "Artemyusi@gmail.com": "ARTEM",
    "Vlad0986613794@gmail.com": "VLAD",
    "nuzhnyias@gmail.com": "ALEX",
  };

  const ruleTips = [
    "This set of rules applies once a day at 12PM.",
    "This set of rules applies only to ABO BID/COST CAP campaigns.",
    "To disable a rule, turn it off for all accounts or use the toggle.",
    "If you disable the accounts or campaigns listed below, this will apply to all rules in this set.",
    "When you edit a rule, you're only editing it for yourself; it won't affect other people's campaigns.",
  ];

  const ruleTips2 = [
    "This set of rules applies every 2 hours after 12PM.",
    "This set of rules applies only to ABO/CBO BID/COST CAP campaigns.",
    "To disable a rule, turn it off for all accounts or use the toggle.",
    "If you disable the accounts or campaigns listed below, this will apply to all rules in this set.",
    "When you edit a rule, you're only editing it for yourself; it won't affect other people's campaigns.",
  ];

  const budgetROITips = [
    "Leave blank to use the default value.",
    "Use a plus sign for ROI if no max value without spaces (e.g., 50+).",
    "Do not use symbols other than authorized symbols (only '+' allowed).",
  ];

  const renderTips = (tipsList) => {
    return tipsList.map((item, key) => {
      const itemKey = `element-${key}`;

      return (
        <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
            {item}
          </MDTypography>
        </MDBox>
      );
    });
  };

  const API_URL = "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app";

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");
  const userName = userNames[userEmail];

  const handleSave = async () => {
    if (!selectedRow) return;
    const updatedData = {
      rule_id: selectedRow.rule_id,
      Profit: tempProfit,
      Profit_VLAD: tempProfit_VLAD,
      Profit_ARTEM: tempProfit_ARTEM,
      Profit_ALEX: tempProfit_ALEX,
      ROI_a_value: tempROI_a_value,
      ROI_a_value_VLAD: tempROI_a_value_VLAD,
      ROI_a_value_ARTEM: tempROI_a_value_ARTEM,
      ROI_a_value_ALEX: tempROI_a_value_ALEX,
      ROI_a_value2: tempROI_a_value2,
      ROI_a_value2_VLAD: tempROI_a_value2_VLAD,
      ROI_a_value2_ARTEM: tempROI_a_value2_ARTEM,
      ROI_a_value2_ALEX: tempROI_a_value2_ALEX,
      daily_budget_value: tempBudget,
      daily_budget_value_VLAD: tempBudget_VLAD,
      daily_budget_value_ARTEM: tempBudget_ARTEM,
      daily_budget_value_ALEX: tempBudget_ALEX,
      user_email: userEmail,
    };
    try {
      await axios.post(`${API_URL}/api/rules/update_rule_s24/`, updatedData);

      const updatedRows = tableData.rows.map((row) =>
        row.rule_id === selectedRow.rule_id
          ? {
              ...row,
              Profit: tempProfit,
              Profit_VLAD: tempProfit_VLAD,
              Profit_ARTEM: tempProfit_ARTEM,
              Profit_ALEX: tempProfit_ALEX,
              ROI_a_value: tempROI_a_value,
              ROI_a_value_VLAD: tempROI_a_value_VLAD,
              ROI_a_value_ARTEM: tempROI_a_value_ARTEM,
              ROI_a_value_ALEX: tempROI_a_value_ALEX,
              ROI_a_value2: tempROI_a_value2,
              ROI_a_value2_VLAD: tempROI_a_value2_VLAD,
              ROI_a_value2_ARTEM: tempROI_a_value2_ARTEM,
              ROI_a_value2_ALEX: tempROI_a_value2_ALEX,
              daily_budget_value: tempBudget,
              daily_budget_value_VLAD: tempBudget_VLAD,
              daily_budget_value_ARTEM: tempBudget_ARTEM,
              daily_budget_value_ALEX: tempBudget_ALEX,
            }
          : row
      );

      setTableData((prevState) => ({
        ...prevState,
        rows: updatedRows,
      }));
      await fetchData();
      toast.success("Data updated successfully!");
      handleEditDialogClose(); // Close the dialog after saving
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/rules/`, {
        rule_names: ["s_24_campaign_1", "s_24_campaign_2", "s_24_campaign_3", "s_24_campaign_4"],
      });

      const rows = response.data.map((rule) => ({
        rule_id: rule.rule_id,
        condition: (
          <pre>
            <div style={{ display: "flex", alignItems: "center" }}>
              {userRole === "admin"
                ? `IF ROI_a yesterday BETWEEN ${rule.ROI_a_value}% AND ${rule.ROI_a_value2}% AND profit yesterday >= ${rule.profit}$`
                : `IF ROI_a yesterday BETWEEN ${rule[`ROI_a_value_${userName}`]}% AND ${
                    rule[`ROI_a_value2_${userName}`]
                  }% AND profit yesterday >= ${rule[`profit_${userName}`]}`}
            </div>
          </pre>
        ),
        ROI_a_value: rule.ROI_a_value,
        ROI_a_value2: rule.ROI_a_value2,
        ROI_a_value_VLAD: rule.ROI_a_value_VLAD,
        ROI_a_value2_VLAD: rule.ROI_a_value2_VLAD,
        ROI_a_value_ARTEM: rule.ROI_a_value_ARTEM,
        ROI_a_value2_ARTEM: rule.ROI_a_value2_ARTEM,
        ROI_a_value_ALEX: rule.ROI_a_value_ALEX,
        ROI_a_value2_ALEX: rule.ROI_a_value2_ALEX,
        action: (
          <pre>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
                alt="Red Arrow"
                style={{ width: "24px", height: "24px", marginRight: "20px" }}
              />
              Set daily budget to&nbsp;&nbsp;{" "}
              <span>
                {userRole === "admin"
                  ? `$${rule.daily_budget_value}`
                  : `$${rule[`daily_budget_value_${userName}`]}`}
              </span>
            </div>
          </pre>
        ),
        daily_budget_value_VLAD: `$${rule.daily_budget_value_VLAD}`,
        daily_budget_value_ARTEM: `$${rule.daily_budget_value_ARTEM}`,
        daily_budget_value_ALEX: `$${rule.daily_budget_value_ALEX}`,
        rule_name: (
          <FormControlLabel
            control={
              <Switch
                checked={userRole === "admin" ? rule.status : rule[`status_${userName}`]}
                onChange={() =>
                  handleToggle(
                    rule.rule_id,
                    userRole === "admin" ? "status" : `status_${userName}`,
                    userRole === "admin" ? rule.status : rule[`status_${userName}`]
                  )
                }
              />
            }
            label={rule.rule_name.replace("_campaign", "_set")}
          />
        ),
        edit: (
          <IconButton onClick={() => handleEditClick(rule)} aria-label="edit" size="large">
            <EditIcon />
          </IconButton>
        ),
      }));

      setTableData((prevState) => ({ ...prevState, rows }));
      setFilteredData(rows); // Display all rows initially
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const userROI_a_value = {
    VLAD: tempROI_a_value_VLAD,
    ARTEM: tempROI_a_value_ARTEM,
    ALEX: tempROI_a_value_ALEX,
  };

  const userROI_a_value2 = {
    VLAD: tempROI_a_value2_VLAD,
    ARTEM: tempROI_a_value2_ARTEM,
    ALEX: tempROI_a_value2_ALEX,
  };

  const userBudget = {
    VLAD: tempBudget_VLAD,
    ARTEM: tempBudget_ARTEM,
    ALEX: tempBudget_ALEX,
  };

  const userProfit = {
    VLAD: tempProfit_VLAD,
    ARTEM: tempProfit_ARTEM,
    ALEX: tempProfit_ALEX,
  };

  const setFunctions = {
    VLAD: {
      setTempROI_a_value: setTempROI_a_value_VLAD,
      setTempROI_a_value2: setTempROI_a_value2_VLAD,
      setTempBudget: setTempBudget_VLAD,
      setTempProfit: setTempProfit_VLAD,
    },
    ARTEM: {
      setTempROI_a_value: setTempROI_a_value_ARTEM,
      setTempROI_a_value2: setTempROI_a_value2_ARTEM,
      setTempBudget: setTempBudget_ARTEM,
      setTempProfit: setTempProfit_ARTEM,
    },
    ALEX: {
      setTempROI_a_value: setTempROI_a_value_ALEX,
      setTempROI_a_value2: setTempROI_a_value2_ALEX,
      setTempBudget: setTempBudget_ALEX,
      setTempProfit: setTempProfit_ALEX,
    },
  };

  const handleValueChange = (field, value) => {
    if (userRole === "admin") {
      switch (field) {
        case "ROI_a_value":
          setTempROI_a_value(value);
          break;
        case "ROI_a_value2":
          setTempROI_a_value2(value);
          break;
        case "Budget":
          setTempBudget(value);
          break;
        case "Profit":
          setTempProfit(value);
          break;
        default:
          break;
      }
    } else {
      const userSetFunctions = setFunctions[userName];
      if (userSetFunctions) {
        switch (field) {
          case "ROI_a_value":
            userSetFunctions.setTempROI_a_value(value);
            break;
          case "ROI_a_value2":
            userSetFunctions.setTempROI_a_value2(value);
            break;
          case "Budget":
            userSetFunctions.setTempBudget(value);
            break;
          case "Profit":
            userSetFunctions.setTempProfit(value);
            break;
          default:
            break;
        }
      }
    }
  };

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
    setTempROI_a_value(row.ROI_a_value);
    setTempROI_a_value_VLAD(row.ROI_a_value_VLAD);
    setTempROI_a_value_ARTEM(row.ROI_a_value_ARTEM);
    setTempROI_a_value_ALEX(row.ROI_a_value_ALEX);

    setTempROI_a_value2(row.ROI_a_value2);
    setTempROI_a_value2_VLAD(row.ROI_a_value2_VLAD);
    setTempROI_a_value2_ARTEM(row.ROI_a_value2_ARTEM);
    setTempROI_a_value2_ALEX(row.ROI_a_value2_ALEX);

    setTempProfit(row.profit);
    setTempProfit_VLAD(row.profit_VLAD);
    setTempProfit_ARTEM(row.profit_ARTEM);
    setTempProfit_ALEX(row.profit_ALEX);

    setTempBudget(row.daily_budget_value);
    setTempBudget_VLAD(row.daily_budget_value_VLAD);
    setTempBudget_ARTEM(row.daily_budget_value_ARTEM);
    setTempBudget_ALEX(row.daily_budget_value_ALEX);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);
    setTempROI_a_value("");
    setTempROI_a_value_VLAD("");
    setTempROI_a_value_ARTEM("");
    setTempROI_a_value_ALEX("");

    setTempROI_a_value2("");
    setTempROI_a_value2_VLAD("");
    setTempROI_a_value2_ARTEM("");
    setTempROI_a_value2_ALEX("");

    setTempProfit("");
    setTempProfit_VLAD("");
    setTempProfit_ARTEM("");
    setTempProfit_ALEX("");

    setTempBudget("");
    setTempBudget_VLAD("");
    setTempBudget_ARTEM("");
    setTempBudget_ALEX("");
  };

  useEffect(() => {
    setFilteredData(
      tableData.rows.filter((row) =>
        Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
      )
    );
  }, [search, tableData.rows]);

  const handleToggle = useCallback(async (ruleId = "", columnName, currentStatus) => {
    const newStatus = currentStatus === true ? "Inactive" : "Active";
    console.log("Current status/new status:", currentStatus, newStatus);

    setTableData((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((row) =>
        row.rule_id === ruleId
          ? {
              ...row,
              [columnName]: newStatus,
              toggleSwitch: (
                <FormControlLabel
                  control={
                    <Switch
                      checked={newStatus === "Active"}
                      onChange={() => handleToggle(ruleId, columnName, newStatus)}
                    />
                  }
                  label=""
                />
              ),
            }
          : row
      ),
    }));

    // Send request to update rule status
    const url = `${API_URL}/api/rules/update_status/`;

    try {
      console.log("ruleId/columnName/newStatus:", ruleId, columnName, newStatus);
      await axios.post(url, {
        rule_id: ruleId,
        column_name: columnName,
        status: newStatus,
        user_email: userEmail,
      });
      // Show success toast after successful update
      toast.success(`Rule status for ID ${ruleId} updated!`);
      await fetchData();
    } catch (error) {
      console.error("Error toggling rule status:", error.response.data);
      // Rollback UI state if update fails
      setTableData((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.rule_id === ruleId ? { ...row, [columnName]: currentStatus } : row
        ),
      }));
      toast.error(`Failed to update rule status for ID ${ruleId}.`);
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Set_1</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips)}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData} entriesPerPage={false} pagination={false} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Set_2</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips2)}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <S24Adsets />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 1 }}>
              <AccountCheckboxList
                ruleName="s_24"
                fetchApiEndpoint={`${API_URL}/api/rules/get_accounts_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 1 }}>
              <CampaignCheckboxList
                ruleName="s_24"
                fetchApiEndpoint={`${API_URL}/api/rules/get_campaigns_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Logs</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(adsetLogsTips)}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%" }}>
              <DataTable table={tableDataAdsetLogs} canSearch entriesPerPage={false} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* Edit Dialog */}
      <DialogFormS24Campaigns
        open={openEditDialog}
        onClose={handleEditDialogClose}
        onSave={handleSave}
        userRole={userRole}
        userName={userName}
        tempROI_a_value={tempROI_a_value}
        userROI_a_value={userROI_a_value}
        tempROI_a_value2={tempROI_a_value2}
        userROI_a_value2={userROI_a_value2}
        tempProfit={tempProfit}
        userProfit={userProfit}
        tempBudget={tempBudget}
        userBudget={userBudget}
        budgetROITips={renderTips(budgetROITips)}
        handleValueChange={handleValueChange}
      />
    </DashboardLayout>
  );
}

export default S24Campaigns;
