import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select as MUISelect,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

const DialogFormStopLoss1V2 = ({
  openEditDialog,
  handleEditDialogClose,
  handleSave,
  selectedRow,
  userRole,
  userName,
  handleValueChange,
  userValue1,
  userValue2,
  userActionValue,
  userNotifyOnly,
  tempValue1,
  tempValue2,
  tempActionValue,
  tempNotifyOnly,
  Tips,
}) => {
  const notifyOptions = ["true", "false"];

  return (
    <Dialog
      open={openEditDialog}
      onClose={handleEditDialogClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          width: "50%",
          height: "70%",
        },
      }}
    >
      <DialogTitle>Edit Rule</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} padding={2}>
          <pre>
            {selectedRow ? (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {selectedRow.condition1 && (
                    <>
                      {selectedRow.condition1}
                      <TextField
                        value={userRole === "admin" ? tempValue1 : userValue1[userName]}
                        onChange={(e) => handleValueChange("setValue1", e.target.value)}
                        variant="standard"
                        size="small"
                        type="number"
                        style={{ width: "6%" }}
                      />
                    </>
                  )}
                  {selectedRow.condition1_1 && (
                    <>
                      {selectedRow.condition1_1}
                      <TextField
                        value={userRole === "admin" ? tempValue2 : userValue2[userName]}
                        onChange={(e) => handleValueChange("setValue2", e.target.value)}
                        variant="standard"
                        size="small"
                        style={{ width: "6%" }}
                      />
                    </>
                  )}
                  {selectedRow.condition1_3 && <>{selectedRow.condition1_3}</>}
                </div>
                <br />
                {selectedRow.action1 && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
                      alt="Red Arrow"
                      style={{ width: "24px", height: "24px", marginRight: "20px" }}
                    />
                    {selectedRow.action1}

                    {selectedRow.action1 !== "Активировать кампанию CBO" &&
                      selectedRow.action1 !== "Активировать адсет" &&
                      selectedRow.action1 !== "Остановить кампанию" &&
                      selectedRow.action1 !== "Остановить адсет" &&
                      selectedRow.action1 !== "Активировать кампанию" &&
                      selectedRow.action1 !== "Активировать кампанию ABO" && (
                        <>
                          <TextField
                            value={
                              userRole === "admin" ? tempActionValue : userActionValue[userName]
                            }
                            onChange={(e) => handleValueChange("setActionValue", e.target.value)}
                            variant="standard"
                            size="small"
                            type="number"
                            style={{ width: "6%" }}
                          />
                          {selectedRow.action1_1 && (
                            <>
                              {selectedRow.action1_1}
                              <TextField
                                value={
                                  userRole === "admin" ? tempActionValue : userActionValue[userName]
                                }
                                onChange={(e) =>
                                  handleValueChange("setActionValue", e.target.value)
                                }
                                variant="standard"
                                size="small"
                                type="number"
                                style={{ width: "6%" }}
                              />
                            </>
                          )}
                        </>
                      )}
                  </div>
                )}
                <br />
                {`✉️ Только отправить уведомление`}&nbsp;&nbsp;
                <MUISelect
                  value={userRole === "admin" ? tempNotifyOnly : userNotifyOnly[userName]}
                  onChange={(e) => handleValueChange("setNotifyOnly", e.target.value)}
                  variant="standard"
                >
                  {notifyOptions.map((option, index) => (
                    <MenuItem key={`${option}-${index}`} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </MUISelect>
              </>
            ) : (
              <div>No data available</div>
            )}
          </pre>
          <MDBox mb={1} mt={4}>
            <MDTypography variant="body2" color="text">
              Follow these tips to ensure correct ROI/Budget/Spend setup
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {Tips}
            </MDBox>
          </MDBox>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button onClick={handleEditDialogClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFormStopLoss1V2;

DialogFormStopLoss1V2.propTypes = {
  openEditDialog: PropTypes.any,
  handleEditDialogClose: PropTypes.any,
  handleSave: PropTypes.any,
  selectedRow: PropTypes.any,
  userRole: PropTypes.any,
  userName: PropTypes.any,
  handleValueChange: PropTypes.any,
  userValue1: PropTypes.any,
  userValue2: PropTypes.any,
  userActionValue: PropTypes.any,
  userNotifyOnly: PropTypes.any,
  tempValue1: PropTypes.any,
  tempValue2: PropTypes.any,
  tempActionValue: PropTypes.any,
  tempNotifyOnly: PropTypes.any,
  Tips: PropTypes.any,
};
