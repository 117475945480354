import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select as MUISelect,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

const DialogFormS24V2 = ({
  openEditDialog,
  handleEditDialogClose,
  handleSave,
  selectedRow,
  userRole,
  userName,
  handleValueChange,
  tempValue_1,
  tempValue_2,
  tempValue_3,
  tempValue_3_1,
  tempValue_4,
  userValue_1,
  userValue_2,
  userValue_3,
  userValue_3_1,
  userValue_4,
  userNotifyOnly,
  tempNotifyOnly,
  Tips,
}) => {
  const notifyOptions = ["true", "false"];

  return (
    <Dialog
      open={openEditDialog}
      onClose={handleEditDialogClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          width: "50%",
          height: "70%",
        },
      }}
    >
      <DialogTitle>Edit Rule</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} padding={2}>
          <pre>
            {/* Проверяем, что selectedRow не null */}
            {selectedRow ? (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* Проверка наличия condition1 */}
                  {selectedRow.condition1 && (
                    <>
                      {selectedRow.condition1}
                      <TextField
                        value={userRole === "admin" ? tempValue_1 : userValue_1[userName]}
                        onChange={(e) => handleValueChange("Value_1", e.target.value)}
                        variant="standard"
                        size="small"
                        type="number"
                        style={{ width: "6%" }}
                      />
                    </>
                  )}
                  {/* Проверка наличия condition1_1 */}
                  {selectedRow.condition1_1 && (
                    <>
                      {selectedRow.condition1_1}
                      <TextField
                        value={userRole === "admin" ? tempValue_2 : userValue_2[userName]}
                        onChange={(e) => handleValueChange("Value_2", e.target.value)}
                        variant="standard"
                        size="small"
                        style={{ width: "6%" }}
                      />
                    </>
                  )}
                  {/* Проверка наличия value_3 */}
                  {selectedRow.condition2}
                  {selectedRow.value_3 && selectedRow.value_3 !== "None" && (
                    <>
                      <TextField
                        value={userRole === "admin" ? tempValue_3 : userValue_3[userName]}
                        onChange={(e) => handleValueChange("Value_3", e.target.value)}
                        variant="standard"
                        size="small"
                        style={{ width: "6%" }}
                      />
                    </>
                  )}
                  {selectedRow.condition2_1}
                  {selectedRow.value_3_1 && selectedRow.value_3_1 !== "None" && (
                    <>
                      <TextField
                        value={userRole === "admin" ? tempValue_3_1 : userValue_3_1[userName]}
                        onChange={(e) => handleValueChange("Value_3_1", e.target.value)}
                        variant="standard"
                        size="small"
                        style={{ width: "6%" }}
                      />
                    </>
                  )}
                  {selectedRow.condition2_2}
                </div>
                <br />
                {/* Проверка наличия action и value_4 */}
                {selectedRow.action && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
                      alt="Red Arrow"
                      style={{ width: "24px", height: "24px", marginRight: "20px" }}
                    />
                    {selectedRow.action} {/* Отображение action */}
                    {selectedRow.value_4 && (
                      <TextField
                        value={userRole === "admin" ? tempValue_4 : userValue_4[userName]}
                        onChange={(e) => handleValueChange("Value_4", e.target.value)}
                        variant="standard"
                        size="small"
                        type="number"
                        style={{ width: "6%" }}
                      />
                    )}
                  </div>
                )}
                <br />
                {`✉️ Только отправить уведомление`}&nbsp;&nbsp;
                <MUISelect
                  value={userRole === "admin" ? tempNotifyOnly : userNotifyOnly[userName]}
                  onChange={(e) => handleValueChange("setNotifyOnly", e.target.value)}
                  variant="standard"
                >
                  {notifyOptions.map((option, index) => (
                    <MenuItem key={`${option}-${index}`} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </MUISelect>
              </>
            ) : (
              <div>No data available</div>
            )}
          </pre>
          <MDBox mb={1} mt={4}>
            <MDTypography variant="body2" color="text">
              Follow these tips to ensure correct ROI/Budget/Spend setup
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {Tips}
            </MDBox>
          </MDBox>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button onClick={handleEditDialogClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFormS24V2;

DialogFormS24V2.propTypes = {
  openEditDialog: PropTypes.any,
  handleEditDialogClose: PropTypes.any,
  handleSave: PropTypes.any,
  selectedRow: PropTypes.any,
  userRole: PropTypes.any,
  userName: PropTypes.any,
  handleValueChange: PropTypes.any,
  tempValue_1: PropTypes.any,
  tempValue_2: PropTypes.any,
  tempValue_3: PropTypes.any,
  tempValue_4: PropTypes.any,
  userValue_1: PropTypes.any,
  userValue_2: PropTypes.any,
  userValue_3: PropTypes.any,
  userValue_4: PropTypes.any,
  userNotifyOnly: PropTypes.any,
  tempNotifyOnly: PropTypes.any,
  tempValue_3_1: PropTypes.any,
  userValue_3_1: PropTypes.any,
  Tips: PropTypes.any,
};
