import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select as MUISelect,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

const DialogFormS24Adsets = ({
  openEditDialog,
  handleEditDialogClose,
  handleSave,
  userRole,
  userName,
  tempRevenueEventsA,
  userRevenueEventsA,
  tempROI_F_min,
  userROI_F_min,
  tempROI_F_max,
  userROI_F_max,
  selectedAction,
  handleActionChange,
  actionOptions,
  bidPercent,
  handleBidPercentChange,
  budgetROITips,
  handleValueChange,
}) => {
  return (
    <Dialog
      open={openEditDialog}
      onClose={handleEditDialogClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          width: "35%",
          height: "60%",
        },
      }}
    >
      <DialogTitle>Edit Rule</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} padding={2}>
          <pre>
            <div>
              IF R_events_a &gt;=&nbsp;&nbsp;{" "}
              <TextField
                value={userRole === "admin" ? tempRevenueEventsA : userRevenueEventsA[userName]}
                onChange={(e) => handleValueChange("RevenueEventsA", e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "8%" }}
              />
              &nbsp;&nbsp;
            </div>
            <div>
              AND ROI_F &nbsp;BETWEEN&nbsp;&nbsp;{" "}
              <TextField
                value={userRole === "admin" ? tempROI_F_min : userROI_F_min[userName]}
                onChange={(e) => handleValueChange("ROI_F_min", e.target.value)}
                variant="standard"
                size="small"
                style={{ width: "8%" }}
              />
              %&nbsp;&nbsp;AND&nbsp;&nbsp;
              <TextField
                value={userRole === "admin" ? tempROI_F_max : userROI_F_max[userName]}
                onChange={(e) => handleValueChange("ROI_F_max", e.target.value)}
                variant="standard"
                size="small"
                style={{ width: "8%" }}
              />
              %
            </div>
            <div>
              ACTION:&nbsp;&nbsp;
              <MUISelect value={selectedAction} onChange={handleActionChange} variant="standard">
                {actionOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </MUISelect>
              &nbsp;&nbsp;
              {selectedAction !== "Do nothing" && selectedAction !== "Stop adset" && (
                <TextField
                  value={bidPercent}
                  onChange={handleBidPercentChange}
                  variant="standard"
                  size="small"
                  type="number"
                  style={{ width: "8%", marginLeft: "8px" }}
                />
              )}
            </div>
          </pre>
        </Box>
        <MDBox mt={5} mb={1} padding={2}>
          <MDTypography variant="body2" color="text">
            Follow these tips to ensure correct setup
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {budgetROITips}
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button onClick={handleEditDialogClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFormS24Adsets;

DialogFormS24Adsets.propTypes = {
  openEditDialog: PropTypes.bool.isRequired,
  handleEditDialogClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  tempRevenueEventsA: PropTypes.number.isRequired,
  userRevenueEventsA: PropTypes.object.isRequired,
  tempROI_F_min: PropTypes.number.isRequired,
  userROI_F_min: PropTypes.object.isRequired,
  tempROI_F_max: PropTypes.number.isRequired,
  userROI_F_max: PropTypes.object.isRequired,
  selectedAction: PropTypes.string.isRequired,
  handleActionChange: PropTypes.func.isRequired,
  actionOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  bidPercent: PropTypes.number.isRequired,
  handleBidPercentChange: PropTypes.func.isRequired,
  budgetROITips: PropTypes.node.isRequired,
  handleValueChange: PropTypes.func.isRequired,
};
