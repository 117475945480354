import React from "react";
import "./styles.css";

// eslint-disable-next-line react/prop-types
const ReusableButton = ({ label, onClickHandler }) => {
  return (
    <div className="button-list-container">
      <div className="button-list-inner-container">
        <button className="button-list-button" onClick={onClickHandler}>
          {label}
        </button>
      </div>
    </div>
  );
};

export default ReusableButton;
