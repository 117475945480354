import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import BasicLayout from "../components/BasicLayout";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { styled } from "@mui/system";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import LowPriorityIcon from "@mui/icons-material/LowPriority";

const StyledLogoutButton = styled(NavLink)(({ theme }) => ({
  display: "inline-block",
  width: "auto",
  padding: theme.spacing(1, 3),
  margin: theme.spacing(1),
  color: theme.palette.error.main,
  border: `2px solid ${theme.palette.error.main}`,
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
  textDecoration: "none",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.error.light,
  },
}));

function LogOut({ setIsLoggedIn }) {
  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userRole");
    setIsLoggedIn(false);
  };

  return (
    <BasicLayout image={bgImage}>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Card>
          <MDBox p={3} textAlign="center">
            <MDTypography variant="h5" mb={3} color="black">
              Are you sure you want to log out?
            </MDTypography>
            <MDButton
              component={StyledLogoutButton}
              onClick={handleLogout}
              size="large"
              to="/authentication/sign-in/basic"
            >
              <MeetingRoomIcon />
              Yes
            </MDButton>
            <MDButton component={StyledLogoutButton} size="large" to="/applications/calendar">
              No
              <LowPriorityIcon />
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </BasicLayout>
  );
}

LogOut.propTypes = {
  setIsLoggedIn: PropTypes.func.isRequired,
};

export default LogOut;
// import React, { useState } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import PropTypes from "prop-types";
// import MDButton from "components/MDButton";
// import BasicLayout from "../components/BasicLayout";
// import Card from "@mui/material/Card";
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
// import { styled } from "@mui/system";
// import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
// import LowPriorityIcon from "@mui/icons-material/LowPriority";
//
// const StyledLogoutButton = styled(NavLink)(({ theme }) => ({
//   display: "inline-block",
//   width: "auto",
//   padding: theme.spacing(1, 3),
//   margin: theme.spacing(1),
//   color: theme.palette.error.main,
//   border: `2px solid ${theme.palette.error.main}`,
//   borderRadius: theme.shape.borderRadius,
//   textAlign: "center",
//   textDecoration: "none",
//   transition: "background-color 0.3s ease",
//   "&:hover": {
//     backgroundColor: theme.palette.error.light,
//   },
// }));
//
// function LogOut({ setIsLoggedIn }) {
//   const [isLoggingOut, setIsLoggingOut] = useState(false); // Защита от множественного клика
//   const navigate = useNavigate();
//
//   const handleLogout = () => {
//     // Очистка локального хранилища
//     localStorage.removeItem("isLoggedIn");
//     localStorage.removeItem("userEmail");
//     localStorage.removeItem("userRole");
//
//     // Обновление состояния с задержкой или в рамках `useEffect`
//     setTimeout(() => setIsLoggedIn(false), 0);
//   };
//
//   return (
//     <BasicLayout image={bgImage}>
//       <MDBox
//         display="flex"
//         flexDirection="column"
//         alignItems="center"
//         justifyContent="center"
//         height="100%"
//       >
//         <Card>
//           <MDBox p={3} textAlign="center">
//             <MDTypography variant="h5" mb={3} color="black">
//               Are you sure you want to log out?
//             </MDTypography>
//             <MDButton
//               component={StyledLogoutButton}
//               onClick={handleLogout}
//               size="large"
//               disabled={isLoggingOut} // Дизейбл кнопки во время логаута
//             >
//               <MeetingRoomIcon />
//               Yes
//             </MDButton>
//             <MDButton component={StyledLogoutButton} size="large" to="/applications/calendar">
//               No
//               <LowPriorityIcon />
//             </MDButton>
//           </MDBox>
//         </Card>
//       </MDBox>
//     </BasicLayout>
//   );
// }
//
// LogOut.propTypes = {
//   setIsLoggedIn: PropTypes.func.isRequired,
// };
//
// export default LogOut;
