import React, { useState, useEffect } from "react";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Snackbar, Alert } from "@mui/material";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Brightness1Icon from "@mui/icons-material/Brightness1";

const AccountCheckboxList = ({
  ruleName,
  fetchApiEndpoint,
  updateApiEndpoint,
  userEmail,
  userRole,
}) => {
  const [accounts, setAccounts] = useState([]);
  const [search, setSearch] = useState("");
  const [modifiedAccounts, setModifiedAccounts] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [showCheckedOnly, setShowCheckedOnly] = useState(true);
  const [filterMode, setFilterMode] = useState("ALL");

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.post(fetchApiEndpoint, {
          role: userRole,
          user_email: userEmail,
        });
        setAccounts(response.data);
        // Initialize modifiedAccounts with current statuses
        const initialStatus = response.data.reduce((acc, account) => {
          acc[account.ID] = account[ruleName];
          return acc;
        }, {});
        setModifiedAccounts(initialStatus);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };

    fetchAccounts();
  }, [fetchApiEndpoint, ruleName]);

  const handleCheckboxChange = (id, checked) => {
    setModifiedAccounts((prev) => ({
      ...prev,
      [id]: checked ? "ACTIVE" : "PAUSED",
    }));
  };

  const handleFilterToggle = () => {
    setFilterMode((prev) => {
      if (prev === "ALL") return "CHECKED";
      if (prev === "CHECKED") return "UNCHECKED";
      return "ALL";
    });
  };

  const handleSave = async () => {
    try {
      await axios.post(updateApiEndpoint, {
        key: "by_account",
        rule_name: ruleName,
        updates: Object.entries(modifiedAccounts).map(([id, newStatus]) => ({
          account_id: id,
          new_status: newStatus,
        })),
      });
      toast.success("Statuses updated successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error("Failed to update statuses.");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Удаляем выбор со всех отфильтрованных аккаунтов
      setModifiedAccounts((prev) => {
        const newState = { ...prev };
        filteredAccounts.forEach((account) => {
          newState[account.ID] = "PAUSED";
        });
        return newState;
      });
    } else {
      // Выбираем все отфильтрованные аккаунты
      setModifiedAccounts((prev) => {
        const newState = { ...prev };
        filteredAccounts.forEach((account) => {
          newState[account.ID] = "ACTIVE";
        });
        return newState;
      });
    }
    setSelectAll(!selectAll);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const filteredAccounts = accounts.filter((account) => {
    const searchLower = search.toLowerCase();
    const nameMatches = account.NAME.toLowerCase().includes(searchLower);
    const idMatches = account.ID.toLowerCase().includes(searchLower);

    if (filterMode === "ALL") {
      return nameMatches || idMatches;
    } else if (filterMode === "CHECKED") {
      return (nameMatches || idMatches) && modifiedAccounts[account.ID] === "ACTIVE";
    } else if (filterMode === "UNCHECKED") {
      // Show only unchecked (PAUSED) accounts
      return (nameMatches || idMatches) && modifiedAccounts[account.ID] === "PAUSED";
    }
  });

  return (
    <Container maxWidth="sm" style={{ marginTop: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <IconButton onClick={handleSelectAll}>
          {selectAll ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
        </IconButton>
        <IconButton onClick={handleFilterToggle}>
          {filterMode === "ALL" ? (
            <Brightness1Icon />
          ) : filterMode === "CHECKED" ? (
            <CheckCircleIcon />
          ) : (
            <CancelIcon />
          )}
        </IconButton>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by name or ID"
          value={search}
          onChange={handleSearchChange}
          style={{ marginBottom: "4px" }}
          sx={{
            height: 36, // Установите высоту, чтобы она соответствовала высоте кнопки
            "& .MuiInputBase-root": {
              // Это для изменения высоты внутреннего элемента текстового поля
              height: "100%",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "500px",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Grid container direction="column" spacing={2}>
          {filteredAccounts.map((account) => (
            <Grid item key={account.ID}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Checkbox
                    checked={modifiedAccounts[account.ID] === "ACTIVE"}
                    onChange={(e) => handleCheckboxChange(account.ID, e.target.checked)}
                    inputProps={{ "aria-label": `checkbox for account ${account.ID}` }}
                  />
                </Grid>
                <Grid item>
                  <Typography fontSize={"0.875rem"}>
                    {account.NAME}: {account.ID}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Button variant="contained" color="white" onClick={handleSave}>
          Save
        </Button>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarMessage.includes("Failed") ? "error" : "success"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

AccountCheckboxList.propTypes = {
  ruleName: PropTypes.any,
  userEmail: PropTypes.any,
  userRole: PropTypes.any,
  fetchApiEndpoint: PropTypes.any,
  updateApiEndpoint: PropTypes.any,
};

export default AccountCheckboxList;
