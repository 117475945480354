import React, { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AntiScam() {
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "update_time", accessor: "update_time", width: "10%" },
      { Header: "campaign_name", accessor: "campaign_name", width: "10%" },
      { Header: "campaign_id", accessor: "campaign_id", width: "10%" },
      { Header: "account_id", accessor: "account_id", width: "10%" },
      { Header: "reason", accessor: "reason", width: "10%" },
      { Header: "is_verified", accessor: "is_verified", width: "10%" },
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://anti-scam-tmgx45bhsa-uc.a.run.app/get_antiscam_campaigns_list/"
        );
        const rows = response.data.map((rule) => ({
          update_time: rule.update_time,
          campaign_name: rule.campaign_name,
          campaign_id: rule.campaign_id,
          account_id: rule.account_id,
          reason: (
            <pre>
              {rule.reason.split("_").map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
            </pre>
          ),
          is_verified: (
            <FormControlLabel
              control={
                <Switch
                  checked={rule.is_verified === "TRUE"}
                  onChange={() => handleCampaignToggle(rule.campaign_id, rule.is_verified)}
                />
              }
              label={rule.is_verified === "TRUE" ? "True" : "False"}
            />
          ),
        }));
        setTableData((prevState) => ({ ...prevState, rows }));
        setFilteredData(rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredData(
      tableData.rows.filter((row) =>
        Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
      )
    );
  }, [search, tableData.rows]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleCampaignToggle = async (campaign_id, is_verified) => {
    const newStatus = is_verified === "TRUE" ? "FALSE" : "TRUE";

    // Update tableData state to reflect the new campaign status and toggle
    setTableData((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((row) =>
        row.campaign_id === campaign_id
          ? {
              ...row,
              is_verified: (
                <FormControlLabel
                  control={
                    <Switch
                      checked={newStatus === "TRUE"}
                      onChange={() => handleCampaignToggle(campaign_id, newStatus)}
                    />
                  }
                  label={newStatus === "TRUE" ? "True" : "False"}
                />
              ),
            }
          : row
      ),
    }));

    axios
      .post("https://anti-scam-tmgx45bhsa-uc.a.run.app/update_is_verified_status/", {
        campaign_id,
        is_verified: newStatus,
      })
      .then(() => {
        toast.success(`Is_verified status for ID ${campaign_id} updated!`);
      })
      .catch((error) => {
        console.error("Error toggling Is_verified:", error);
        setTableData((prevState) => ({
          ...prevState,
          rows: prevState.rows.map((row) =>
            row.campaign_id === campaign_id
              ? {
                  ...row,
                  is_verified: (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={is_verified === "TRUE"}
                          onChange={() => handleCampaignToggle(campaign_id, is_verified)}
                        />
                      }
                      label={is_verified === "TRUE" ? "True" : "False"}
                    />
                  ),
                }
              : row
          ),
        }));
        toast.error(`Failed to update campaign Is_verified status for ID ${campaign_id}.`);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <DataTable table={{ columns: tableData.columns, rows: filteredData }} canSearch />
      </MDBox>
    </DashboardLayout>
  );
}

export default AntiScam;
