import React from "react";
import {
  Paper,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";

const FilterByGeoPartnerCategory = ({
  setSelectedGeo,
  selectedGeo,
  setSelectedPartner,
  selectedPartner,
  setSelectedCategory,
  selectedCategory,
  geoOptions,
  partnerOptions,
  categoryOptions,
}) => {
  return (
    <Paper sx={{ width: "100%", padding: 2, mb: 2 }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        mb={2}
        sx={{
          gap: 0,
          width: "100%",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Filter by Geo, Partner & Category
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {/* Дропдаун для выбора Geo */}
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel shrink>Geo</InputLabel>
            <Autocomplete
              value={selectedGeo}
              onChange={(event, newValue) => setSelectedGeo(newValue)}
              options={geoOptions.map((option) => option.label)}
              renderInput={(params) => <TextField {...params} label="Geo" variant="standard" />}
            />
          </FormControl>

          {/* Дропдаун для выбора Partner */}
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel shrink>Partner</InputLabel>
            <Autocomplete
              value={selectedPartner}
              onChange={(event, newValue) => setSelectedPartner(newValue)}
              options={partnerOptions.map((option) => option.label)}
              renderInput={(params) => <TextField {...params} label="Partner" variant="standard" />}
            />
          </FormControl>

          {/* Дропдаун для выбора Category */}
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel shrink>Category</InputLabel>
            <Autocomplete
              value={selectedCategory}
              onChange={(event, newValue) => setSelectedCategory(newValue)}
              options={categoryOptions.map((option) => option.label)}
              renderInput={(params) => (
                <TextField {...params} label="Category" variant="standard" />
              )}
            />
          </FormControl>
        </Box>
      </Box>
    </Paper>
  );
};

FilterByGeoPartnerCategory.propTypes = {
  setSelectedGeo: PropTypes.func.isRequired,
  selectedGeo: PropTypes.string.isRequired,
  setSelectedPartner: PropTypes.func.isRequired,
  selectedPartner: PropTypes.string.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  geoOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  partnerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FilterByGeoPartnerCategory;
