import React, { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "../../../examples/Tables/DataTable";
import "react-toastify/dist/ReactToastify.css";

const scrollStyle = {
  maxHeight: "200px",
  maxWidth: "200px",
  overflowY: "auto",
};

export function useTableDataAdsetQueue() {
  const [tableDataAdsetQueue, setTableDataAdsetQueue] = useState({
    columns: [
      { Header: "update_time", accessor: "update_time", width: "10%" },
      { Header: "activate_time", accessor: "activate_time", width: "10%" },
      { Header: "activate_provider_time", accessor: "activate_provider_time", width: "10%" },
      { Header: "paused_time", accessor: "paused_time", width: "10%" },
      {
        Header: "adset name",
        accessor: "adset_name",
        width: "10%",
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => <div style={scrollStyle}>{value}</div>,
      },
      { Header: "account_id", accessor: "account_id", width: "10%" },
      { Header: "status", accessor: "adset_status_after", width: "10%" },
      { Header: "reason", accessor: "reason", width: "10%" },
      { Header: "provider", accessor: "provider", width: "10%" },
      { Header: "paused_count", accessor: "paused_count", width: "10%" },
      { Header: "adset_id", accessor: "adset_id", width: "10%" },
      { Header: "Spend", accessor: "Spend", width: "10%" },
      { Header: "Revenue_f", accessor: "Revenue_f", width: "10%" },
      { Header: "Revenue_a", accessor: "Revenue_a", width: "10%" },
      { Header: "ROI_f", accessor: "ROI_f", width: "10%" },
      { Header: "ROI", accessor: "ROI", width: "10%" },
      { Header: "Profit_f", accessor: "Profit_f", width: "10%" },
      { Header: "Profit", accessor: "Profit", width: "10%" },
      { Header: "rule_id", accessor: "rule_id", width: "10%" },
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/adsets_under_rule_queue/"
        );
        const rows = response.data.map((rule) => ({
          update_time: rule.update_time,
          status: rule.adset_status_after,
          rule_name: rule.rule_name,
          reason: (
            <pre>
              {rule.reason.split(", ").map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
            </pre>
          ),
          provider: rule.provider,
          paused_count: rule.paused_count,
          adset_name: rule.adset_name,
          account_id: rule.account_id,
          adset_id: rule.adset_id,
          paused_time: rule.paused_time,
          activate_time: rule.activate_time,
          activate_provider_time: rule.activate_provider_time,
          adset_budget_before: rule.adset_budget_before,
          adset_budget_after: rule.adset_budget_after,
          rule_id: rule.rule_id,
          Spend: parseFloat(rule.Spend).toFixed(2),
          Revenue_a: parseFloat(rule.Revenue_a).toFixed(2),
          Revenue_f: parseFloat(rule.Revenue_f).toFixed(2),
          ROI: parseFloat(rule.ROI).toFixed(2),
          ROI_f: parseFloat(rule.ROI_f).toFixed(2),
          Profit_f: parseFloat(rule.Profit_f).toFixed(2),
          Profit: parseFloat(rule.Profit).toFixed(2),
        }));
        setTableDataAdsetQueue((prevState) => ({ ...prevState, rows }));
        setFilteredData(rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return tableDataAdsetQueue;
}

export function useFilteredData(tableDataAdsetQueue, search) {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(
      tableDataAdsetQueue.rows.filter((row) =>
        Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
      )
    );
  }, [search, tableDataAdsetQueue.rows]);

  return filteredData;
}

export function handleSearchChange(event, setSearch) {
  setSearch(event.target.value);
}

function AdsetsUnderRuleQueue() {
  const [search, setSearch] = useState("");
  const tableDataAdsetLogs = useTableDataAdsetQueue();
  const filteredData = useFilteredData(tableDataAdsetLogs, search);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={filteredData} canSearch entriesPerPage={false} />
            </Paper>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AdsetsUnderRuleQueue;
