import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { useLayoutEffect, useState, useRef } from "react";

function DataTableHeadCell({ width, children, sorted, align, disableSortBy, ...rest }) {
  const [computedWidth, setComputedWidth] = useState(width);
  const textRef = useRef(null);

  useLayoutEffect(() => {
    if (textRef.current) {
      // Вычисляем ширину текста
      const textWidth = textRef.current.scrollWidth;
      // Устанавливаем ширину в два раза больше ширины текста
      setComputedWidth(`${textWidth * 2}px`);
    }
  }, [children]); // Пересчитываем, когда содержимое заголовка изменяется

  return (
    <MDBox
      component="th"
      width={computedWidth} // Используем вычисленную ширину
      py={1.5}
      px={3}
      sx={({ palette: { light }, borders: { borderWidth } }) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,
        minWidth: "200px", // Устанавливаем минимальную ширину
        backgroundColor: "rgba(23,23,24,0.4)",
      })}
    >
      <MDBox
        ref={textRef} // Ссылка для вычисления ширины
        {...rest}
        position="relative"
        textAlign={align}
        color="black" // Change header text color
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold } }) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          textTransform: "uppercase",
          cursor: disableSortBy ? "default" : sorted && "pointer",
          userSelect: disableSortBy ? "none" : sorted && "none",
          whiteSpace: "nowrap", // Не переносим текст
          overflow: "hidden", // Обрезаем текст
          textOverflow: "ellipsis", // Добавляем многоточие
        })}
      >
        {children}
        {!disableSortBy && sorted && (
          <MDBox
            position="absolute"
            top={0}
            right={align !== "right" ? "16px" : 0}
            left={align === "right" ? "-5px" : "unset"}
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            <MDBox
              position="absolute"
              top={-6}
              color={sorted === "asce" ? "text" : "secondary"}
              opacity={sorted === "asce" ? 1 : 0.5}
            >
              <Icon>arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={0}
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon>arrow_drop_down</Icon>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
  disableSortBy: PropTypes.string,
};

export default DataTableHeadCell;
