import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import React from "react";

function DataTableBodyCell({
  noBorder,
  align,
  children,
  maxHeight = "700px",
  height,
  isUrl,
  urlParam, // Добавляем пропс для указания параметра URL
  ...rest
}) {
  let displayText = children;

  if (isUrl && typeof children === "object" && children.props?.cell?.value) {
    try {
      const url = new URL(children.props.cell.value);
      const id = url.searchParams.get(urlParam); // Используем urlParam для получения значения
      displayText = id ? id : children.props.cell.value;
    } catch (e) {
      console.error("Invalid URL:", children.props.cell.value, e);
      displayText = children.props.cell.value;
    }
  }

  const content = isUrl ? (
    <a
      href={children.props?.cell?.value} // Используем исходный URL
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "blue", textDecoration: "underline" }}
    >
      {displayText}
    </a>
  ) : (
    children
  );

  return (
    <MDBox
      component="td"
      {...rest}
      textAlign={align}
      sx={({ borders: { borderWidth }, palette: { light } }) => ({
        fontSize: "0.875rem",
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
        padding: "12px 16px",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        wordBreak: "break-word",
        overflowWrap: "break-word",
        maxHeight: maxHeight,
        height: height,
        overflow: "hidden",
      })}
    >
      <MDBox
        sx={{
          maxHeight: "inherit",
          height: "inherit",
          overflowY: "auto",
        }}
      >
        {content}
      </MDBox>
    </MDBox>
  );
}

DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
  maxHeight: "700px",
  height: "auto",
  isUrl: false,
  urlParam: "",
};

DataTableBodyCell.propTypes = {
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.string,
  height: PropTypes.string,
  isUrl: PropTypes.bool,
  urlParam: PropTypes.string, // Добавлен новый PropType для urlParam
};

export default DataTableBodyCell;
