import React from "react";
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import MDBox from "../../components/MDBox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SearchOffIcon from "@mui/icons-material/SearchOff";
const FilterByColumnNameMinMax = ({
  // eslint-disable-next-line react/prop-types
  selectedColumn,
  // eslint-disable-next-line react/prop-types
  onColumnChange,
  // eslint-disable-next-line react/prop-types
  minValue,
  // eslint-disable-next-line react/prop-types
  onMinValueChange,
  // eslint-disable-next-line react/prop-types
  maxValue,
  // eslint-disable-next-line react/prop-types
  onMaxValueChange,
  // eslint-disable-next-line react/prop-types
  columnName,
}) => {
  const handleClearClick = () => {
    onMinValueChange("");
    onMaxValueChange("");
  };

  return (
    <Paper sx={{ width: "100%", padding: 1, mb: 1 }}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1} mb={0}>
        <Grid container spacing={2}>
          <MDBox py={3} px={3} sx={{ width: "100%" }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Filter by {columnName}
            </Typography>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} md={5}>
                <TextField
                  label="Min"
                  variant="outlined"
                  type="number"
                  fullWidth
                  size="small"
                  margin="dense"
                  value={minValue}
                  onChange={(e) => onMinValueChange(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="Max"
                  variant="outlined"
                  type="number"
                  size="small"
                  margin="dense"
                  fullWidth
                  value={maxValue}
                  onChange={(e) => onMaxValueChange(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center" }}>
                <IconButton onClick={handleClearClick} color="gray">
                  <SearchOffIcon sx={{ fontSize: "small" }} />
                </IconButton>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Box>
    </Paper>
  );
};

export default FilterByColumnNameMinMax;
