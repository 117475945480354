import React, { useEffect, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "./styles.css";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomDropdown from "../dropdown_component";
import Box from "@mui/material/Box";
import FilterByColumnNameSearch from "../../task_management/search_by_column_name";
import Typography from "@mui/material/Typography";
import CustomInputField from "../input_field";
import CustomInputFieldMultiline from "../input_field_multiline";
import CustomNumericInputField from "../input_number_field";
import Button from "@mui/material/Button";

function UpdateCampaign() {
  const API_URL = "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app";
  const API_URL_UPDATE = "https://fb-campaign-creator-only-campaigns-tmgx45bhsa-uc.a.run.app";
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [inputRFolderNameGD, setInputRFolderNameGD] = useState("");
  const [inputAdsPerAdset, setInputAdsPerAdset] = useState("");

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const [selectedCampaignName, setSelectedCampaignName] = useState("");
  const [CampaignNamesAll, setCampaignNamesAll] = useState([]);

  const [selectedAdsetName, setSelectedAdsetName] = useState("");
  const [AdsetNamesAll, setAdsetNamesAll] = useState([]);
  const [filteredAdsets, setFilteredAdsets] = useState([]);

  const [selectedType, setSelectedType] = useState("");
  const typesAll = [
    "CBO",
    "ABO",
    "CBO | BID_CAP",
    "ABO | BID_CAP",
    "CBO | COST_CAP",
    "ABO | COST_CAP",
    "ADVANTAGE_PLUS",
  ];

  const [selectedAccount, setSelectedAccount] = useState("");
  const [accountsAll, setAccountsAll] = useState([]);

  const [selectedPage, setSelectedPage] = useState("");
  const [pagesAll, setPagesAll] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const languagesAll = [
    "Abkhaz",
    "Afar",
    "Afrikaans",
    "Akan",
    "Albanian",
    "Amharic",
    "Arabic",
    "Armenian",
    "Assamese",
    "Aymara",
    "Azerbaijani",
    "Bambara",
    "Bashkir",
    "Basque",
    "Belarusian",
    "Bengali",
    "Bihari",
    "Bislama",
    "Bosnian",
    "Breton",
    "Bulgarian",
    "Burmese",
    "Catalan",
    "Chamorro",
    "Chechen",
    "Chinese",
    "Chuvash",
    "Cornish",
    "Corsican",
    "Croatian",
    "Czech",
    "Danish",
    "Divehi",
    "Dutch",
    "Dzongkha",
    "English",
    "Esperanto",
    "Estonian",
    "Ewe",
    "Faroese",
    "Fijian",
    "Finnish",
    "French",
    "Galician",
    "Georgian",
    "German",
    "Greek",
    "Greenlandic",
    "Guarani",
    "Gujarati",
    "Haitian",
    "Hausa",
    "Hebrew",
    "Herero",
    "Hindi",
    "Hiri Motu",
    "Hungarian",
    "Icelandic",
    "Igbo",
    "Indonesian",
    "Interlingua",
    "Interlingue",
    "Inuktitut",
    "Inupiaq",
    "Irish",
    "Italian",
    "Japanese",
    "Javanese",
    "Kannada",
    "Kanuri",
    "Kashmiri",
    "Kazakh",
    "Khmer",
    "Kikuyu",
    "Kinyarwanda",
    "Kirghiz",
    "Kirundi",
    "Komi",
    "Kongo",
    "Korean",
    "Kuanyama",
    "Kurdish",
    "Lao",
    "Latin",
    "Latvian",
    "Limburgish",
    "Lingala",
    "Lithuanian",
    "Luba-Katanga",
    "Luxembourgish",
    "Macedonian",
    "Malagasy",
    "Malay",
    "Malayalam",
    "Maltese",
    "Manx",
    "Maori",
    "Marathi",
    "Marshallese",
    "Mongolian",
    "Nauru",
    "Navajo",
    "Ndebele",
    "Ndonga",
    "Nepali",
    "North Ndebele",
    "Northern Sami",
    "Norwegian",
    "Occitan",
    "Ojibwa",
    "Oriya",
    "Oromo",
    "Ossetian",
    "Pali",
    "Pashto",
    "Persian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Quechua",
    "Romansh",
    "Romanian",
    "Russian",
    "Samoan",
    "Sango",
    "Sanskrit",
    "Serbian",
    "Shona",
    "Sindhi",
    "Sinhala",
    "Slovak",
    "Slovenian",
    "Somali",
    "South Ndebele",
    "Southern Sotho",
    "Spanish",
    "Sundanese",
    "Swahili",
    "Swati",
    "Swedish",
    "Tagalog",
    "Tahitian",
    "Tajik",
    "Tamil",
    "Tatar",
    "Telugu",
    "Thai",
    "Tibetan",
    "Tigrinya",
    "Tonga",
    "Tsonga",
    "Tswana",
    "Turkish",
    "Turkmen",
    "Twi",
    "Uighur",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Venda",
    "Vietnamese",
    "Volapük",
    "Walloon",
    "Welsh",
    "Western Frisian",
    "Wolof",
    "Xhosa",
    "Yiddish",
    "Yoruba",
    "Zhuang",
    "Zulu",
  ];

  const [selectedGeo, setSelectedGeo] = useState("");
  const geoAll = [
    "AD",
    "AE",
    "AF",
    "AG",
    "AI",
    "AL",
    "AM",
    "AO",
    "AQ",
    "AR",
    "AS",
    "AT",
    "AU",
    "AW",
    "AX",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BF",
    "BG",
    "BH",
    "BI",
    "BJ",
    "BL",
    "BM",
    "BN",
    "BO",
    "BQ",
    "BR",
    "BS",
    "BT",
    "BV",
    "BW",
    "BY",
    "BZ",
    "CA",
    "CC",
    "CD",
    "CF",
    "CG",
    "CH",
    "CI",
    "CK",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CU",
    "CV",
    "CW",
    "CX",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DM",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "EH",
    "ER",
    "ES",
    "ET",
    "FI",
    "FJ",
    "FM",
    "FO",
    "FR",
    "GA",
    "GB",
    "GD",
    "GE",
    "GF",
    "GG",
    "GH",
    "GI",
    "GL",
    "GM",
    "GN",
    "GP",
    "GQ",
    "GR",
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HM",
    "HN",
    "HR",
    "HT",
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IO",
    "IQ",
    "IR",
    "IS",
    "IT",
    "JE",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KI",
    "KM",
    "KN",
    "KP",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LB",
    "LC",
    "LI",
    "LK",
    "LR",
    "LS",
    "LT",
    "LU",
    "LV",
    "LY",
    "MA",
    "MC",
    "MD",
    "ME",
    "MF",
    "MG",
    "MH",
    "MK",
    "ML",
    "MM",
    "MN",
    "MO",
    "MP",
    "MQ",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NF",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NR",
    "NU",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PF",
    "PG",
    "PH",
    "PK",
    "PL",
    "PM",
    "PN",
    "PR",
    "PT",
    "PW",
    "PY",
    "QA",
    "RE",
    "RO",
    "RS",
    "RW",
    "SA",
    "SB",
    "SC",
    "SD",
    "SE",
    "SG",
    "SH",
    "SI",
    "SJ",
    "SK",
    "SL",
    "SM",
    "SN",
    "SO",
    "SR",
    "SS",
    "ST",
    "SV",
    "SX",
    "SY",
    "SZ",
    "TC",
    "TD",
    "TF",
    "TG",
    "TH",
    "TJ",
    "TK",
    "TL",
    "TM",
    "TN",
    "TO",
    "TR",
    "TT",
    "TV",
    "TZ",
    "UA",
    "UG",
    "US",
    "UY",
    "UZ",
    "VA",
    "VC",
    "VE",
    "VG",
    "VI",
    "VN",
    "VU",
    "WF",
    "WS",
    "YE",
    "YT",
    "ZA",
    "ZM",
    "ZW",
    "FK",
    "XK",
    "AN",
    "PS",
    "GS",
    "TW",
    "UM",
  ];

  const [selectedStage, setSelectedStage] = useState("");
  const [stagesAll, setStagesAll] = useState(["L1", "L2"]);
  const [selectedOptimEvent, setSelectedOptimEvent] = useState("");
  const [optimEventsAll, setOptimEventsAll] = useState([]);
  const [selectedFbPixel, setSelectedFbPixel] = useState("");
  const [fbPixelsAll, setFbPixelsAll] = useState([]);
  const [inputLinkForFB, setInputLinkForFB] = useState("");
  const [inputAdjAdText, setInputAdjAdText] = useState("");
  const [inputAdjAdCopy, setInputAdjAdCopy] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [inputBudget, setInputBudget] = useState("");
  const [inputBidAmount, setInputBidAmount] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const accountsResponse = await fetch(`${API_URL}/get_accounts_all/`);
        const accountsData = await accountsResponse.json();
        setAccountsAll(accountsData);

        const pageResponse = await fetch(`${API_URL}/get_pages_all/`);
        const pagesData = await pageResponse.json();
        setPagesAll(pagesData);

        const campaignsConstantsAll = await fetch(`${API_URL}/get_constants_fb_ready_to_launch/`);
        const campaignsConstants = await campaignsConstantsAll.json();
        // setStagesAll(campaignsConstants["d___campaign_stages"]);
        setOptimEventsAll(campaignsConstants["fb___optimisation_events"]);
        setFbPixelsAll(campaignsConstants["fb___pixels"]);
        const newStages = campaignsConstants["d___campaign_stages"];
        const combinedStages = Array.from(new Set([...stagesAll, ...newStages]));
        setStagesAll(combinedStages);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_URL}/get_campaigns_names_adsets_types/`);
        const data = await response.json();

        const emailsNames = {
          "Artemyusi@gmail.com": "Artem",
          "Vlad0986613794@gmail.com": "Vlad",
          "achudak23@gmail.com": "Anton",
          "nuzhnyias@gmail.com": "Alex",
          "xxxuko@gmail.com": "Anya",
          "Hembarskyi@gmail.com": "Michail",
          "andrew@salesbrush.com": "Andrew",
          "andreylipovskiy0@gmail.com": "Andrey",
          "mcerneva2@gmail.com": "Maryna",
        };

        let filteredCampaignNames;
        let filteredAdsetNames;

        if (userRole === "admin") {
          // Extract unique values from the data for admin role
          filteredCampaignNames = [...new Set(data.map((item) => item.campaign_id_name))];
          filteredAdsetNames = [...new Set(data.map((item) => item.adset_id_name))];
        } else {
          const userName = emailsNames[userEmail];
          // Filter campaign and adset names based on the user's name
          filteredCampaignNames = [
            ...new Set(
              data
                .map((item) => item.campaign_id_name)
                .filter((campaignName) => campaignName.includes(userName))
            ),
          ];
          filteredAdsetNames = [
            ...new Set(
              data
                .map((item) => item.adset_id_name)
                .filter((adsetName) => adsetName.includes(userName))
            ),
          ];
        }

        // Update state with filtered values
        setCampaignNamesAll(filteredCampaignNames);
        setAdsetNamesAll(filteredAdsetNames);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCampaignName) {
      const campaignId = selectedCampaignName.split(" | ")[0];
      const filtered = AdsetNamesAll.filter((adset) => adset.includes(`(${campaignId})`));
      setFilteredAdsets(filtered);
    } else {
      setFilteredAdsets([]);
    }
  }, [selectedCampaignName, setFilteredAdsets]);

  const handleUpdateCampaign = () => {
    // Проверяем, что заполнены хотя бы необходимые поля
    if (!inputRFolderNameGD || !selectedCampaignName || !selectedType) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL_UPDATE}/campaign_update/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        properties: {
          campaign_id: selectedCampaignName,
          adset_id: selectedAdsetName,
          r___folder_name__google_drive_: inputRFolderNameGD,
          ads_per_adset: inputAdsPerAdset,
          campaign_type: selectedType,
          ad_account: selectedAccount,
          fb__link_for_fb: inputLinkForFB,
          headline: inputAdjAdText,
          primary_text: inputAdjAdCopy,
          fb___page: selectedPage,
          description: inputDescription,
          fb___geo: selectedGeo,
          fb___optimisation_event: selectedOptimEvent,
          fb___pixel: selectedFbPixel,
          language: selectedLanguage,
          d___campaign_stage: selectedStage,
          bid_cost_amount: inputBidAmount,
          daily_budget: inputBudget,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("Campaign successfully updated!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleUpdateCampaign}>
            Update campaign
          </button>
        </div>
      </div>
    );
  };

  const ResponseMessage = () => {
    return (
      <div className="response-message-container">
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <Card className="response-message" sx={{ overflow: "auto" }}>
            <pre>{responseMessage}</pre>
          </Card>
        )}
      </div>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={3}>
                <MDTypography variant="h5">Update campaign 🔄</MDTypography>
              </MDBox>
              <MDBox p={2}>
                <MDTypography variant="h6" sx={{ color: "grey" }}>
                  General info (Required)
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <CustomDropdown
                          selectedCategory={selectedCampaignName}
                          setSelectedCategory={setSelectedCampaignName}
                          categories={CampaignNamesAll}
                          category_name={"Campaign ID | Name"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Default: New adset
                        </Typography>
                        <CustomDropdown
                          selectedCategory={selectedAdsetName}
                          setSelectedCategory={setSelectedAdsetName}
                          categories={filteredAdsets}
                          // categories={AdsetNamesAll}
                          category_name={"Adset ID | Name"}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Default: 1Adset/All_Ads
                        </Typography>
                        <CustomNumericInputField
                          inputValue={inputAdsPerAdset}
                          setInputValue={setInputAdsPerAdset}
                          label={"Ads per adset"}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedAccount}
                          setSelectedCategory={setSelectedAccount}
                          categories={accountsAll}
                          category_name={"FB - AD account"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedType}
                          setSelectedCategory={setSelectedType}
                          categories={typesAll}
                          category_name={"D - Campaign type"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedLanguage}
                          setSelectedCategory={setSelectedLanguage}
                          categories={languagesAll}
                          category_name={"FB - Language"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedPage}
                          setSelectedCategory={setSelectedPage}
                          categories={pagesAll}
                          category_name={"FB - page"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          inputValue={inputRFolderNameGD}
                          setInputValue={setInputRFolderNameGD}
                          label={"r___folder_name__google_drive_"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          inputValue={inputAdjAdText}
                          setInputValue={setInputAdjAdText}
                          label={"Headline"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputFieldMultiline
                          inputValue={inputLinkForFB}
                          setInputValue={setInputLinkForFB}
                          label={"fb__link_for_fb"}
                          multiline
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInputFieldMultiline
                          inputValue={inputAdjAdCopy}
                          setInputValue={setInputAdjAdCopy}
                          label={"Ad text"}
                          multiline
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInputFieldMultiline
                          inputValue={inputDescription}
                          setInputValue={setInputDescription}
                          label={"Ad Description"}
                          multiline
                          required={true}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>
              <MDBox p={2}>
                <MDTypography variant="h6" sx={{ color: "grey" }}>
                  Fields for new AdSet creation
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <CustomDropdown
                      selectedCategory={selectedOptimEvent}
                      setSelectedCategory={setSelectedOptimEvent}
                      categories={optimEventsAll}
                      category_name={"fb___optimisation_event"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomDropdown
                      selectedCategory={selectedFbPixel}
                      setSelectedCategory={setSelectedFbPixel}
                      categories={fbPixelsAll}
                      category_name={"fb___pixel"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomDropdown
                      selectedCategory={selectedStage}
                      setSelectedCategory={setSelectedStage}
                      categories={stagesAll}
                      category_name={"d___campaign_stage"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomDropdown
                      selectedCategory={selectedGeo}
                      setSelectedCategory={setSelectedGeo}
                      categories={geoAll}
                      category_name={"FB - Geo"}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                      Required for BID/COST_CAP ⚠️ (Default: $1)
                    </Typography>
                    <CustomNumericInputField
                      inputValue={inputBidAmount}
                      setInputValue={setInputBidAmount}
                      label={"Bid/Cost amount (in $)"}
                      required={false}
                      allowFloat={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                      Required for ABO ⚠️ (Default: $5)
                    </Typography>
                    <CustomNumericInputField
                      inputValue={inputBudget}
                      setInputValue={setInputBudget}
                      label={"Daily budget (in $)"}
                      required={false}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={2} px={2}>
                <ButtonList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UpdateCampaign;
