import React from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";

// eslint-disable-next-line react/prop-types
const FilterByColumnNameSearch = ({
  // eslint-disable-next-line react/prop-types
  searchTerm,
  // eslint-disable-next-line react/prop-types
  onSearchTermChange,
  // eslint-disable-next-line react/prop-types
  columnName,
  // eslint-disable-next-line react/prop-types
  columnLabel,
}) => {
  return (
    <Paper sx={{ width: "100%", padding: 1.47, mb: 2 }}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} mb={1}>
        <Typography variant="h6">Search by {columnLabel}</Typography>
        <TextField
          label={`Search by ${columnLabel}`}
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={onSearchTermChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Paper>
  );
};

export default FilterByColumnNameSearch;
