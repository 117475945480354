/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import BasicLayout from "../../components/BasicLayout";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { toast } from "react-toastify";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputAdornment, InputLabel, Select } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";

function Cover() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const handleSignUp = async () => {
    try {
      const response = await axios.post(
        "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/sign_up/",
        {
          email,
          password,
          role,
        }
      );
      console.log("Response data:", response.data);

      if (response.data.message === "User created successfully") {
        toast.success("Registration successful!");
        // setTimeout(() => {
        //   navigate("/authentication/sign-in/basic");
        // }, 4000);
      } else if (response.data.message === "Sign up failed") {
        toast.error("Registration failed. Please try again.");
      }
    } catch (error) {
      console.error("Error signing up:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Add new user
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter user email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={(e) => e.preventDefault()}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox mb={3}>
              <FormControl fullWidth>
                <InputLabel htmlFor="role-select">Role</InputLabel>
                <Select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  inputProps={{
                    name: "role",
                    id: "role-select",
                  }}
                  style={{ height: "50px" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                >
                  <MenuItem value="user">user</MenuItem>
                  <MenuItem value="admin">admin</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSignUp}>
                Register
              </MDButton>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDButton
                  variant="variant"
                  size="large"
                  component={Link}
                  to="/applications/calendar"
                >
                  Back
                </MDButton>
              </MDBox>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              {/*<MDTypography variant="button" color="text">*/}
              {/*  Already have an account?{" "}*/}
              {/*  <MDTypography*/}
              {/*    component={Link}*/}
              {/*    to="/authentication/sign-in/basic"*/}
              {/*    variant="button"*/}
              {/*    color="info"*/}
              {/*    fontWeight="medium"*/}
              {/*    textGradient*/}
              {/*  >*/}
              {/*    Sign In*/}
              {/*  </MDTypography>*/}
              {/*</MDTypography>*/}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
