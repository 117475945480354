import React from "react";
import {
  Paper,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";

const FilterCard = ({
  selectedColumn,
  setSelectedColumn,
  selectedStatus,
  setSelectedStatus,
  statuses,
  md_names,
}) => {
  return (
    <Paper sx={{ width: "100%", padding: 2, mb: 2 }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        mb={2}
        sx={{
          gap: 0.9,
          width: "100%",
        }}
      >
        {/* Заголовок */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          Sort by MediaBuyer Name & Status
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "gray", mb: 2 }}>
          ! Select a name to apply the filter
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {/* Дропдаун для выбора колонки */}
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <Autocomplete
              value={selectedColumn}
              onChange={(event, newValue) => setSelectedColumn(newValue)}
              options={md_names}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              renderInput={(params) => (
                <TextField {...params} label="Media Buyer Name" variant="standard" />
              )}
            />
          </FormControl>

          {/* Дропдаун для выбора статуса */}
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <Autocomplete
              value={selectedStatus}
              onChange={(event, newValue) => setSelectedStatus(newValue)}
              options={statuses}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              renderInput={(params) => <TextField {...params} label="Status" variant="standard" />}
            />
          </FormControl>
        </Box>
      </Box>
    </Paper>
  );
};

FilterCard.propTypes = {
  selectedColumn: PropTypes.string.isRequired,
  setSelectedColumn: PropTypes.func.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  setSelectedStatus: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  md_names: PropTypes.array.isRequired,
};

export default FilterCard;
