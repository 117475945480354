import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";

// Функция для форматирования даты в DD.MM.YYYY
const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

// eslint-disable-next-line react/prop-types
const DateRangeFilter = ({ onDateRangeChange }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const defaultStartDate = formatDate(new Date("2022-01-01"));
  const defaultEndDate = formatDate(new Date("2100-01-01"));

  const handleApplyClick = () => {
    const appliedStartDate = startDate || defaultStartDate;
    const appliedEndDate = endDate || defaultEndDate;

    onDateRangeChange({
      startDate: formatDate(appliedStartDate),
      endDate: formatDate(appliedEndDate),
    });
  };
  const handleClearClick = () => {
    setStartDate("");
    setEndDate("");
    onDateRangeChange({ startDate: "", endDate: "" });
  };

  return (
    <Paper sx={{ width: "100%", padding: 2, mb: 2 }}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} mb={1.3}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Filter by Date
        </Typography>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <Box display="flex" gap={2} mt={0}>
          <Button variant="contained" color="white" size="small" onClick={handleApplyClick}>
            Apply
          </Button>
          <Button variant="contained" color="white" size="small" onClick={handleClearClick}>
            Clear
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default DateRangeFilter;
