import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import MDBox from "../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import DialogFormS24Adsets from "./s24adsets_dialog";
import Grid from "@mui/material/Grid";

function S24Adsets() {
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "15%" },
      { Header: "condition", accessor: "condition", disableSortBy: true, width: "55%" },
      { Header: "action", accessor: "action", disableSortBy: true, width: "35%" },
      { Header: "EDIT", accessor: "edit", disableSortBy: true, width: "5%" },
    ],
    rows: [],
  });
  const API_URL = "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app";

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [tempROI_F_min, setTempROI_F_min] = useState("");
  const [tempROI_F_min_VLAD, setTempROI_F_min_VLAD] = useState("");
  const [tempROI_F_min_ARTEM, setTempROI_F_min_ARTEM] = useState("");
  const [tempROI_F_min_ALEX, setTempROI_F_min_ALEX] = useState("");

  const [tempROI_F_max, setTempROI_F_max] = useState("");
  const [tempROI_F_max_VLAD, setTempROI_F_max_VLAD] = useState("");
  const [tempROI_F_max_ARTEM, setTempROI_F_max_ARTEM] = useState("");
  const [tempROI_F_max_ALEX, setTempROI_F_max_ALEX] = useState("");

  const [tempRevenueEventsA, setTempRevenueEventsA] = useState("");
  const [tempRevenueEventsA_VLAD, setTempRevenueEventsA_VLAD] = useState("");
  const [tempRevenueEventsA_ARTEM, setTempRevenueEventsA_ARTEM] = useState("");
  const [tempRevenueEventsA_ALEX, setTempRevenueEventsA_ALEX] = useState("");

  const [tempBIDPercent, setTempBIDPercent] = useState("");
  const [tempBIDPercent_VLAD, setTempBIDPercent_VLAD] = useState("");
  const [tempBIDPercent_ARTEM, setTempBIDPercent_ARTEM] = useState("");
  const [tempBIDPercent_ALEX, setTempBIDPercent_ALEX] = useState("");

  const userNames = {
    "Artemyusi@gmail.com": "ARTEM",
    "Vlad0986613794@gmail.com": "VLAD",
    "nuzhnyias@gmail.com": "ALEX",
  };

  const userName = userNames[userEmail];

  const [tempAction, setTempAction] = useState("");
  const [selectedAction, setSelectedAction] = useState("");

  const budgetROITips = [
    "Leave blank to use the default value.",
    "Use the dash ( - ) symbol if there is no minimum value.",
    "Use the plus ( + ) symbol if there is no maximum value.",
    "Use a dash for negative ROI values without spaces (ex: -55).",
  ];

  const actionOptions = [
    "Do nothing",
    "Current Bid % decrement on",
    "Stop adset",
    "Current Bid % increment on",
    "Current Bid % decrement on",
  ];

  const renderTips = (tipsList) => {
    return tipsList.map((item, key) => {
      const itemKey = `element-${key}`;

      return (
        <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
            {item}
          </MDTypography>
        </MDBox>
      );
    });
  };

  const handleSave = async () => {
    if (!selectedRow) return;
    const updatedData = {
      rule_id: selectedRow.rule_id,
      ROI_f_min: tempROI_F_min,
      ROI_f_min_VLAD: tempROI_F_min_VLAD,
      ROI_f_min_ARTEM: tempROI_F_min_ARTEM,
      ROI_f_min_ALEX: tempROI_F_min_ALEX,
      ROI_f_max: tempROI_F_max,
      ROI_f_max_VLAD: tempROI_F_max_VLAD,
      ROI_f_max_ARTEM: tempROI_F_max_ARTEM,
      ROI_f_max_ALEX: tempROI_F_max_ALEX,
      revenue_events_a: tempRevenueEventsA,
      revenue_events_a_VLAD: tempRevenueEventsA_VLAD,
      revenue_events_a_ARTEM: tempRevenueEventsA_ARTEM,
      revenue_events_a_ALEX: tempRevenueEventsA_ALEX,
      BID_percent: tempBIDPercent,
      BID_percent_VLAD: tempBIDPercent_VLAD,
      BID_percent_ARTEM: tempBIDPercent_ARTEM,
      BID_percent_ALEX: tempBIDPercent_ALEX,
      action: selectedAction,
      user_email: userEmail, // Ensure userEmail is properly defined
    };

    try {
      await axios.post(`${API_URL}/api/rules/update_rule_s24_adsets/`, updatedData);

      const updatedRows = tableData.rows.map((row) =>
        row.rule_id === selectedRow.rule_id
          ? {
              ...row,
              ROI_f_min: tempROI_F_min,
              ROI_f_max: tempROI_F_max,
              revenue_events_a: tempRevenueEventsA,
              BID_percent: tempBIDPercent,
              ROI_f_min_VLAD: tempROI_F_min_VLAD,
              ROI_f_max_VLAD: tempROI_F_max_VLAD,
              revenue_events_a_VLAD: tempRevenueEventsA_VLAD,
              BID_percent_VLAD: tempBIDPercent_VLAD,
              ROI_f_min_ARTEM: tempROI_F_min_ARTEM,
              ROI_f_max_ARTEM: tempROI_F_max_ARTEM,
              revenue_events_a_ARTEM: tempRevenueEventsA_ARTEM,
              BID_percent_ARTEM: tempBIDPercent_ARTEM,
              ROI_f_min_ALEX: tempROI_F_min_ALEX,
              ROI_f_max_ALEX: tempROI_F_max_ALEX,
              revenue_events_a_ALEX: tempRevenueEventsA_ALEX,
              BID_percent_ALEX: tempBIDPercent_ALEX,
              action: selectedAction,
            }
          : row
      );

      setTableData((prevState) => ({
        ...prevState,
        rows: updatedRows,
      }));
      await fetchData();
      toast.success("Data updated successfully!");
      handleEditDialogClose(); // Close the dialog after saving
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/rules/`, {
        rule_names: [
          "s_24_adset_1",
          "s_24_adset_2",
          "s_24_adset_3",
          "s_24_adset_4",
          "s_24_adset_5",
        ],
      });

      const rows = response.data.map((rule) => ({
        rule_id: rule.rule_id,
        rule_name: (
          <FormControlLabel
            control={
              <Switch
                checked={userRole === "admin" ? rule.status : rule[`status_${userName}`]}
                onChange={() =>
                  handleToggle(
                    rule.rule_id,
                    userRole === "admin" ? "status" : `status_${userName}`,
                    userRole === "admin" ? rule.status : rule[`status_${userName}`]
                  )
                }
              />
            }
            label={rule.rule_name}
          />
        ),
        condition: (
          <pre>
            <div>
              {userRole === "admin" ? (
                <>
                  IF R_events_a <strong> &gt;= {rule.revenue_events_a}</strong>
                  &nbsp;AND roi_f BETWEEN <strong>{rule.ROI_f_min}%</strong>
                  &nbsp;AND&nbsp; <strong>{rule.ROI_f_max}%</strong>
                </>
              ) : (
                <>
                  IF R_events_a{" "}
                  <strong style={{ fontSize: "larger" }}>
                    {" "}
                    &nbsp;&gt;= {rule[`revenue_events_a_${userName}`]}
                  </strong>
                  &nbsp;&nbsp;AND&nbsp;&nbsp; roi_f &nbsp;&nbsp;BETWEEN&nbsp;&nbsp;{" "}
                  <strong style={{ fontSize: "larger" }}>{rule[`ROI_f_min_${userName}`]}%</strong>
                  &nbsp;&nbsp;AND&nbsp;&nbsp;{" "}
                  <strong style={{ fontSize: "larger" }}>{rule[`ROI_f_max_${userName}`]}%</strong>
                </>
              )}
            </div>
          </pre>
        ),
        action: (
          <pre>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
                alt="Red Arrow"
                style={{ width: "24px", height: "24px", marginRight: "20px" }}
              />
              {rule.action}
              {(userRole === "admin" ? rule.BID_percent : rule[`BID_percent_${userName}`]) !==
                null &&
                (userRole === "admin"
                  ? rule.BID_percent !== "None"
                  : rule[`BID_percent_${userName}`] !== "None") && (
                  <>
                    &nbsp;
                    <strong style={{ fontSize: "larger" }}>
                      {userRole === "admin"
                        ? `${rule.BID_percent}`
                        : `${rule[`BID_percent_${userName}`]}`}
                    </strong>
                  </>
                )}
            </div>
          </pre>
        ),
        ROI_F_min: rule.ROI_f_min,
        ROI_f_min_VLAD: rule.ROI_f_min_VLAD,
        ROI_f_min_ARTEM: rule.ROI_f_min_ARTEM,
        ROI_f_min_ALEX: rule.ROI_f_min_ALEX,
        ROI_F_max: rule.ROI_f_max,
        ROI_f_max_VLAD: rule.ROI_f_max_VLAD,
        ROI_f_max_ARTEM: rule.ROI_f_max_ARTEM,
        ROI_f_max_ALEX: rule.ROI_f_max_ALEX,
        BID_percent: rule.BID_percent,
        BID_percent_VLAD: rule.BID_percent_VLAD,
        BID_percent_ARTEM: rule.BID_percent_ARTEM,
        BID_percent_ALEX: rule.BID_percent_ALEX,
        revenue_events_a: rule.revenue_events_a,
        revenue_events_a_VLAD: rule.revenue_events_a_VLAD,
        revenue_events_a_ARTEM: rule.revenue_events_a_ARTEM,
        revenue_events_a_ALEX: rule.revenue_events_a_ALEX,
        edit: (
          <IconButton onClick={() => handleEditClick(rule)} aria-label="edit" size="large">
            <EditIcon />
          </IconButton>
        ),
      }));

      setTableData((prevState) => ({ ...prevState, rows }));
      setFilteredData(rows); // Display all rows initially
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const userROI_F_min = {
    VLAD: tempROI_F_min_VLAD,
    ARTEM: tempROI_F_min_ARTEM,
    ALEX: tempROI_F_min_ALEX,
  };

  const userROI_F_max = {
    VLAD: tempROI_F_max_VLAD,
    ARTEM: tempROI_F_max_ARTEM,
    ALEX: tempROI_F_max_ALEX,
  };

  const userRevenueEventsA = {
    VLAD: tempRevenueEventsA_VLAD,
    ARTEM: tempRevenueEventsA_ARTEM,
    ALEX: tempRevenueEventsA_ALEX,
  };

  const userBIDPercent = {
    VLAD: tempBIDPercent_VLAD,
    ARTEM: tempBIDPercent_ARTEM,
    ALEX: tempBIDPercent_ALEX,
  };

  const [bidPercent, setBidPercent] = useState(
    userRole === "admin" ? tempBIDPercent : userBIDPercent[userName]
  );

  const setFunctions = {
    VLAD: {
      setTempROI_F_min: setTempROI_F_min_VLAD,
      setTempROI_F_max: setTempROI_F_max_VLAD,
      setTempRevenueEventsA: setTempRevenueEventsA_VLAD,
      setTempBIDPercent: setTempBIDPercent_VLAD,
    },
    ARTEM: {
      setTempROI_F_min: setTempROI_F_min_ARTEM,
      setTempROI_F_max: setTempROI_F_max_ARTEM,
      setTempRevenueEventsA: setTempRevenueEventsA_ARTEM,
      setTempBIDPercent: setTempBIDPercent_ARTEM,
    },
    ALEX: {
      setTempROI_F_min: setTempROI_F_min_ALEX,
      setTempROI_F_max: setTempROI_F_max_ALEX,
      setTempRevenueEventsA: setTempRevenueEventsA_ALEX,
      setTempBIDPercent: setTempBIDPercent_ALEX,
    },
  };

  const handleValueChange = (field, value) => {
    if (userRole === "admin") {
      switch (field) {
        case "ROI_F_min":
          setTempROI_F_min(value);
          break;
        case "ROI_F_max":
          setTempROI_F_max(value);
          break;
        case "RevenueEventsA":
          setTempRevenueEventsA(value);
          break;
        case "BIDPercent":
          setTempBIDPercent(value);
          break;
        default:
          break;
      }
    } else {
      const userSetFunctions = setFunctions[userName];
      if (userSetFunctions) {
        switch (field) {
          case "ROI_F_min":
            userSetFunctions.setTempROI_F_min(value);
            break;
          case "ROI_F_max":
            userSetFunctions.setTempROI_F_max(value);
            break;
          case "RevenueEventsA":
            userSetFunctions.setTempRevenueEventsA(value);
            break;
          case "BIDPercent":
            userSetFunctions.setTempBIDPercent(value);
            break;
          default:
            break;
        }
      }
    }
  };

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
    setTempAction(row.action);
    setSelectedAction(row.action);
    setBidPercent(row.BID_percent);

    setTempROI_F_min(row.ROI_f_min);
    setTempROI_F_min_VLAD(row.ROI_f_min_VLAD);
    setTempROI_F_min_ARTEM(row.ROI_f_min_ARTEM);
    setTempROI_F_min_ALEX(row.ROI_f_min_ALEX);

    setTempROI_F_max(row.ROI_f_max);
    setTempROI_F_max_VLAD(row.ROI_f_max_VLAD);
    setTempROI_F_max_ARTEM(row.ROI_f_max_ARTEM);
    setTempROI_F_max_ALEX(row.ROI_f_max_ALEX);

    setTempRevenueEventsA(row.revenue_events_a);
    setTempRevenueEventsA_VLAD(row.revenue_events_a_VLAD);
    setTempRevenueEventsA_ARTEM(row.revenue_events_a_ARTEM);
    setTempRevenueEventsA_ALEX(row.revenue_events_a_ALEX);

    setTempBIDPercent(row.BID_percent);
    setTempBIDPercent_VLAD(row.BID_percent_VLAD);
    setTempBIDPercent_ARTEM(row.BID_percent_ARTEM);
    setTempBIDPercent_ALEX(row.BID_percent_ALEX);
  };

  useEffect(() => {
    // Optionally, log or debug state changes
    console.log("Selected Action:", selectedAction);
    console.log("Bid Percent:", bidPercent);
  }, [selectedAction, bidPercent]);

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);
    setTempAction("");
    setSelectedAction("");

    setTempROI_F_min("");
    setTempROI_F_min_VLAD("");
    setTempROI_F_min_ARTEM("");
    setTempROI_F_min_ALEX("");

    setTempROI_F_max("");
    setTempROI_F_max_VLAD("");
    setTempROI_F_max_ARTEM("");
    setTempROI_F_max_ALEX("");

    setTempRevenueEventsA("");
    setTempRevenueEventsA_VLAD("");
    setTempRevenueEventsA_ARTEM("");
    setTempRevenueEventsA_ALEX("");

    setTempBIDPercent("");
    setTempBIDPercent_VLAD("");
    setTempBIDPercent_ARTEM("");
    setTempBIDPercent_ALEX("");
  };

  const handleToggle = useCallback(async (ruleId = "", columnName, currentStatus) => {
    const newStatus = currentStatus === true ? "Inactive" : "Active";
    console.log("Current status/new status:", currentStatus, newStatus);

    setTableData((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((row) =>
        row.rule_id === ruleId
          ? {
              ...row,
              [columnName]: newStatus,
              toggleSwitch: (
                <FormControlLabel
                  control={
                    <Switch
                      checked={newStatus === "Active"}
                      onChange={() => handleToggle(ruleId, columnName, newStatus)}
                    />
                  }
                  label=""
                />
              ),
            }
          : row
      ),
    }));

    // Send request to update rule status
    const url = `${API_URL}/api/rules/update_status/`;

    try {
      console.log("ruleId/columnName/newStatus:", ruleId, columnName, newStatus);
      await axios.post(url, {
        rule_id: ruleId,
        column_name: columnName,
        status: newStatus,
        user_email: userEmail,
      });
      // Show success toast after successful update
      toast.success(`Rule status for ID ${ruleId} updated!`);
      await fetchData();
    } catch (error) {
      console.error("Error toggling rule status:", error.response.data);
      // Rollback UI state if update fails
      setTableData((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.rule_id === ruleId ? { ...row, [columnName]: currentStatus } : row
        ),
      }));
      toast.error(`Failed to update rule status for ID ${ruleId}.`);
    }
  }, []);

  const handleActionChange = (event) => {
    setSelectedAction(event.target.value);
  };

  const handleBidPercentChange = (event) => {
    setBidPercent(event.target.value);
  };

  return (
    <>
      <Card sx={{ width: "100%" }}>
        <DataTable table={tableData} entriesPerPage={false} pagination={false} />
      </Card>
      {/* Edit Dialog */}
      <DialogFormS24Adsets
        openEditDialog={openEditDialog}
        handleEditDialogClose={handleEditDialogClose}
        handleSave={handleSave}
        userRole={userRole}
        userName={userName}
        tempRevenueEventsA={tempRevenueEventsA}
        userRevenueEventsA={userRevenueEventsA}
        tempROI_F_min={tempROI_F_min}
        userROI_F_min={userROI_F_min}
        tempROI_F_max={tempROI_F_max}
        userROI_F_max={userROI_F_max}
        selectedAction={selectedAction}
        handleActionChange={handleActionChange}
        actionOptions={actionOptions}
        bidPercent={bidPercent}
        handleBidPercentChange={handleBidPercentChange}
        budgetROITips={renderTips(budgetROITips)}
        handleValueChange={handleValueChange}
      />
    </>
  );
}

export default S24Adsets;
