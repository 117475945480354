import { DialogContent } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import React from "react";
import PropTypes from "prop-types";

const DialogForm = ({
  selectedRow,
  userRole,
  userName,
  handleValueChange,
  tempROI_A,
  tempBudget,
  tempROI_F_1,
  tempSpend,
  tempROI_F_2,
  userROI_A,
  userBudget,
  userROI_F_1,
  userSpend,
  userROI_F_2,
  budgetROITips,
  userROI_A_activate_campaign,
  userROI_A_activate_adset,
  userL_1_budget,
  userL_1_spend,
  userABO_adset_spend_percent,
  userABO_adset_roi_f,
  tempROI_a_activate_campaign,
  tempROI_a_activate_adset,
  tempL_1_budget,
  tempL_1_spend,
  tempABO_adset_spend_percent,
  tempABO_adset_roi_f,
  userROI_a_5_days,
  tempROI_a_5_days,
}) => {
  return (
    <DialogContent>
      <Box display="flex" flexDirection="column" gap={2} padding={2}>
        <pre>
          ОПИСАНИЕ:
          <br />
          1. {selectedRow.condition1}
          <br />
          1.1 {selectedRow.condition1_1}
          <br />
          <br />
          <div>
            2. {selectedRow.condition2}&nbsp;&nbsp;
            <TextField
              value={userRole === "admin" ? tempROI_A : userROI_A[userName]}
              onChange={(e) => handleValueChange("ROI_A", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            %
          </div>
          <div>
            2.1 {selectedRow.condition2_1}&nbsp;&nbsp;
            <TextField
              value={
                userRole === "admin"
                  ? tempROI_a_activate_campaign
                  : userROI_A_activate_campaign[userName]
              }
              onChange={(e) => handleValueChange("ROI_a_activate_campaign", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            %
          </div>
          <div>
            2.2 {selectedRow.condition2_2}&nbsp;&nbsp;
            <TextField
              value={
                userRole === "admin"
                  ? tempROI_a_activate_campaign
                  : userROI_A_activate_campaign[userName]
              }
              onChange={(e) => handleValueChange("ROI_a_activate_campaign", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            %
          </div>
          <div>
            2.3 {selectedRow.condition2_3}&nbsp;&nbsp;
            <TextField
              value={
                userRole === "admin" ? tempROI_a_activate_adset : userROI_A_activate_adset[userName]
              }
              onChange={(e) => handleValueChange("ROI_a_activate_adset", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            %
          </div>
          <div>
            2.4 {selectedRow.condition2_4}&nbsp;&nbsp;
            <TextField
              value={userRole === "admin" ? tempROI_a_5_days : userROI_a_5_days[userName]}
              onChange={(e) => handleValueChange("ROI_a_5_days", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            %
          </div>
          <br />
          <div>
            3. {selectedRow.condition1_2}&nbsp;&nbsp;
            <TextField
              value={userRole === "admin" ? tempL_1_budget : userL_1_budget[userName]}
              onChange={(e) => handleValueChange("L_1_budget", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            %
          </div>
          <br />
          УСЛОВИЕ:
          <div>
            1. {selectedRow.condition3}&nbsp;&gt;=&nbsp;
            <TextField
              value={userRole === "admin" ? tempBudget : userBudget[userName]}
              onChange={(e) => handleValueChange("Budget", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            % И {selectedRow.condition4} &lt;=&nbsp;
            <TextField
              value={userRole === "admin" ? tempROI_F_1 : userROI_F_1[userName]}
              onChange={(e) => handleValueChange("ROI_F_1", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            &nbsp;%
          </div>
          <div>
            2. {selectedRow.condition3_1}&nbsp;
            <TextField
              value={
                userRole === "admin"
                  ? tempABO_adset_spend_percent
                  : userABO_adset_spend_percent[userName]
              }
              onChange={(e) => handleValueChange("ABO_adset_spend_percent", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            % И {selectedRow.condition3_2}&nbsp;
            <TextField
              value={userRole === "admin" ? tempABO_adset_roi_f : userABO_adset_roi_f[userName]}
              onChange={(e) => handleValueChange("ABO_adset_roi_f", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            &nbsp;%
          </div>
          <div>
            3. {selectedRow.condition5}&nbsp;&gt;=&nbsp;
            <TextField
              value={userRole === "admin" ? tempSpend : userSpend[userName]}
              onChange={(e) => handleValueChange("Spend", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            &nbsp;$&nbsp;&nbsp;И {selectedRow.condition6} &lt;=&nbsp;
            <TextField
              value={userRole === "admin" ? tempROI_F_2 : userROI_F_2[userName]}
              onChange={(e) => handleValueChange("ROI_F_2", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            &nbsp;%
          </div>
          <div>
            4. {selectedRow.condition4_1}&nbsp;
            <TextField
              value={userRole === "admin" ? tempL_1_spend : userL_1_spend[userName]}
              onChange={(e) => handleValueChange("L_1_spend", e.target.value)}
              variant="standard"
              size="small"
              type="number"
              style={{ width: "6%" }}
            />
            $
          </div>
        </pre>
        <MDBox mb={1} mt={4}>
          <MDTypography variant="body2" color="text">
            Follow these tips to ensure correct ROI/Budget/Spend setup
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {budgetROITips}
          </MDBox>
        </MDBox>
      </Box>
    </DialogContent>
  );
};

export default DialogForm;

DialogForm.propTypes = {
  selectedRow: PropTypes.any,
  userRole: PropTypes.any,
  userName: PropTypes.any,
  handleValueChange: PropTypes.any,
  tempROI_A: PropTypes.any,
  tempBudget: PropTypes.any,
  tempROI_F_1: PropTypes.any,
  tempSpend: PropTypes.any,
  tempROI_F_2: PropTypes.any,
  userROI_A: PropTypes.any,
  userBudget: PropTypes.any,
  userROI_F_1: PropTypes.any,
  userSpend: PropTypes.any,
  userROI_F_2: PropTypes.any,
  budgetROITips: PropTypes.any,
  generateDescription: PropTypes.any,
  userROI_A_activate_adset: PropTypes.any,
  userL_1_budget: PropTypes.any,
  userROI_a_5_days: PropTypes.any,
  userL_1_spend: PropTypes.any,
  userABO_adset_spend_percent: PropTypes.any,
  userABO_adset_roi_f: PropTypes.any,
  tempROI_a_activate_adset: PropTypes.any,
  tempL_1_budget: PropTypes.any,
  tempROI_a_5_days: PropTypes.any,
  tempL_1_spend: PropTypes.any,
  tempABO_adset_spend_percent: PropTypes.any,
  tempABO_adset_roi_f: PropTypes.any,
  userROI_A_activate_campaign: PropTypes.any,
  tempROI_a_activate_campaign: PropTypes.any,
};
