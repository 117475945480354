import React, { useEffect } from "react";
import { Box, Typography, FormControl, TextField } from "@mui/material";
import PropTypes from "prop-types";

const CustomNumericInputField = ({
  inputValue,
  setInputValue,
  label,
  required,
  additionalInfo,
  allowFloat,
}) => {
  const [touched, setTouched] = React.useState(false);

  useEffect(() => {
    if (required) {
      setTouched(true);
    }
  }, [required]);

  const handleBlur = () => {
    setTouched(true);
  };

  const handleChange = (e) => {
    // Convert the value to a number and set the state
    const value = allowFloat ? parseFloat(e.target.value) : parseInt(e.target.value, 10);
    setInputValue(value);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mb={1}
      sx={{
        gap: 2,
        width: "100%",
      }}
    >
      <FormControl variant="standard" sx={{ width: "100%" }}>
        <TextField
          value={inputValue}
          onChange={handleChange}
          label={label}
          inputProps={{
            step: allowFloat ? "any" : "1", // 'any' allows float numbers
            type: "number",
          }}
          type="number"
          sx={{ width: "100%" }}
          onBlur={handleBlur}
          required={required}
          error={required && touched && (inputValue === "" || isNaN(inputValue))}
        />
      </FormControl>
    </Box>
  );
};

CustomNumericInputField.propTypes = {
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setInputValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  additionalInfo: PropTypes.string,
  allowFloat: PropTypes.bool,
};

CustomNumericInputField.defaultProps = {
  required: false,
  additionalInfo: "",
  allowFloat: false,
};

export default CustomNumericInputField;
