import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputAdornment,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import MDInput from "../../../components/MDInput";
import FilterCard from "../filter_card_name_status";
import CustomTablePagination from "../custom_pagination";
import Box from "@mui/material/Box";
import RequestButton from "../update_data_button";
import DateRangeFilter from "../filter_by_date";
import SearchField from "../custom_search";
import MUISelect from "@mui/material/Select";
import Select from "react-select";
import FilterByGeoPartnerCategory from "../filter_by_geo_partner_category";

function SelectedCategories() {
  useEffect(() => {
    const element = document.querySelector(".MuiBox-root.css-fxid9l");
    if (element) {
      element.remove();
    }
  }, []);

  const [tableData, setTableData] = useState({
    columns: [
      { Header: "Date", accessor: "Date" },
      { Header: "Source", accessor: "Source" },
      { Header: "Category", accessor: "Category" },
      { Header: "Partner", accessor: "Partner" },
      { Header: "Geo", accessor: "Geo" },
      { Header: "Comments", accessor: "Comments" },
      { Header: "Artem_Y", accessor: "ArtemY", disableSortBy: true },
      { Header: "Vlad_A", accessor: "VladA", disableSortBy: true },
      { Header: "Alex_N", accessor: "AlexN", disableSortBy: true },
      { Header: "Andrei_L", accessor: "AndreiL", disableSortBy: true },
      { Header: "MarinaC", accessor: "MarinaC", disableSortBy: true },
      { Header: "Artem_Y_Campaign_IDs", accessor: "Artem_Y_Campaign_IDs" },
      { Header: "Vlad_A_Campaign_IDs", accessor: "Vlad_A_Campaign_IDs" },
      { Header: "Alex_N_Campaign_IDs", accessor: "Alex_N_Campaign_IDs" },
      { Header: "Andrei_L_Campaign_IDs", accessor: "Andrei_L_Campaign_IDs" },
      { Header: "Marina_C_Campaign_IDs", accessor: "Marina_C_Campaign_IDs" },
      { Header: "EDIT", accessor: "edit", disableSortBy: true },
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [tempComments, setTempComments] = useState("");
  const [tempArtem_Y_Campaign_IDs, setTempArtem_Y_Campaign_IDs] = useState("");
  const [tempVlad_A_Campaign_IDs, setTempVlad_A_Campaign_IDs] = useState("");
  const [tempAlex_N_Campaign_IDs, setTempAlex_N_Campaign_IDs] = useState("");
  const [tempAndrei_L_Campaign_IDs, setTempAndrei_L_Campaign_IDs] = useState("");
  const [tempMarina_C_Campaign_IDs, setTempMarina_C_Campaign_IDs] = useState("");

  const statuses = ["Launched", "Prepearing", "Not used", "Whitelisted"];
  const md_names = ["ArtemY", "VladA", "AntonC", "AlexN", "AndreiL", "MarinaC"];

  const userEmail = localStorage.getItem("userEmail");

  const [selectedColumn, setSelectedColumn] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [startDate, setStartDate] = useState(""); // Состояние для начальной даты
  const [endDate, setEndDate] = useState(""); // Состояние для конечной даты

  const [filteredData, setFilteredData] = useState([]);

  const [selectedGeo, setSelectedGeo] = useState("");
  const [selectedPartner, setSelectedPartner] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [geoOptions, setGeoOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/selected_categories/"
        );
        const rows = response.data.map((rule) => ({
          Date: rule.Date,
          Source: rule.Source,
          Category: rule.Category,
          Partner: rule.Partner,
          Geo: rule.Geo,
          Comments: rule.Comments,
          ArtemY: rule.ArtemY,
          VladA: rule.VladA,
          AntonC: rule.AntonC,
          AlexN: rule.AlexN,
          AndreiL: rule.AndreiL,
          MarinaC: rule.MarinaC,
          Artem_Y_Campaign_IDs: rule.Artem_Y_Campaign_IDs,
          Vlad_A_Campaign_IDs: rule.Vlad_A_Campaign_IDs,
          Alex_N_Campaign_IDs: rule.Alex_N_Campaign_IDs,
          Andrei_L_Campaign_IDs: rule.Andrei_L_Campaign_IDs,
          Marina_C_Campaign_IDs: rule.Marina_C_Campaign_IDs,
        }));

        setTableData((prevState) => ({ ...prevState, rows }));
        setFilteredData(rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [categoryResponse, partnerResponse, geoResponse] = await Promise.all([
          axios.get(
            "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/selected_categories_category/"
          ),
          axios.get(
            "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/selected_categories_partner/"
          ),
          axios.get("https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/selected_categories_geo/"),
        ]);
        const sortedCategories = categoryResponse.data.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        const sortedPartners = partnerResponse.data.sort((a, b) => a.label.localeCompare(b.label));
        const sortedGeo = geoResponse.data.sort((a, b) => a.label.localeCompare(b.label));

        setCategoryOptions(sortedCategories);
        setPartnerOptions(sortedPartners);
        setGeoOptions(sortedGeo);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  const handleDateRangeChange = (dateRange) => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
  };

  const handleStatusChange = (row, column) => async (event) => {
    const updatedValue = event.target.value;

    const updatedData = {
      Source: row.Source,
      Category: row.Category,
      Partner: row.Partner,
      Geo: row.Geo,
      Artem_Y_Campaign_IDs: row.Artem_Y_Campaign_IDs,
      Vlad_A_Campaign_IDs: row.Vlad_A_Campaign_IDs,
      Alex_N_Campaign_IDs: row.Alex_N_Campaign_IDs,
      Andrei_L_Campaign_IDs: row.Andrei_L_Campaign_IDs,
      Marina_C_Campaign_IDs: row.Marina_C_Campaign_IDs,
      Comments: row.Comments,
      [column]: updatedValue,
      user_email: localStorage.getItem("userEmail"),
    };

    setTableData((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((r) =>
        r.Source === row.Source &&
        r.Category === row.Category &&
        r.Partner === row.Partner &&
        r.Geo === row.Geo
          ? { ...r, [column]: updatedValue }
          : r
      ),
    }));

    try {
      await axios.post(
        "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/selected_categories_update/",
        updatedData
      );
      toast.success("Selected categories updated successfully!");
    } catch (error) {
      console.error("Error updating data:", error.response.data);
      toast.error("Failed to update selected categories!");
    }
  };

  const renderSelect = (row, column) => {
    const currentValue = row[column];
    const borderColor =
      currentValue === "Launched"
        ? "green"
        : currentValue === "Prepearing"
        ? "yellow"
        : currentValue === "Not used"
        ? "red"
        : "purple";
    const backgroundColor =
      borderColor === "green"
        ? "rgba(5,250,5,0.2)"
        : borderColor === "yellow"
        ? "rgba(248,248,2,0.2)"
        : borderColor === "red"
        ? "rgba(255, 0, 0, 0.2)"
        : "rgba(86,0,255,0.2)";

    const handleSelectChange = (event) => {
      const selectedStatus = event.target.value;
      const userEmail = localStorage.getItem("userEmail");
      const role = localStorage.getItem("userRole");
      console.log(role);

      // Define a mapping of user emails to the corresponding column names
      const emailToColumn = {
        "Artemyusi@gmail.com": "Artem_Y_Campaign_IDs",
        "Vlad0986613794@gmail.com": "Vlad_A_Campaign_IDs",
        "nuzhnyias@gmail.com": "Alex_N_Campaign_IDs",
        "andreylipovskiy0@gmail.com": "Andrei_L_Campaign_IDs",
        "mcerneva2@gmail.com": "Marina_C_Campaign_IDs",
      };

      // Get the Campaign IDs column name based on the user's email
      const campaignColumn = emailToColumn[userEmail];

      if (selectedStatus === "Launched") {
        if (role === "admin") {
          alert("You have admin access and can change statuses without Campaign IDs.");
          handleStatusChange(row, column)(event);
        } else if (!row[campaignColumn] || row[campaignColumn].length === 0) {
          alert("Please provide Campaign IDs to set the status to 'Launched'.");
        } else {
          handleStatusChange(row, column)(event);
        }
      } else {
        handleStatusChange(row, column)(event);
      }
    };

    return (
      <MUISelect
        value={row[column]}
        onChange={handleSelectChange}
        sx={{
          color: borderColor,
          border: `1px solid ${borderColor}`,
          borderRadius: "4px",
          backgroundColor: backgroundColor,
          "&:hover": {
            backgroundColor: backgroundColor,
          },
        }}
        variant="outlined"
      >
        {statuses.map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </MUISelect>
    );
  };

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
    setTempComments(row.Comments);
    setTempArtem_Y_Campaign_IDs(row.Artem_Y_Campaign_IDs);
    setTempVlad_A_Campaign_IDs(row.Vlad_A_Campaign_IDs);
    setTempAlex_N_Campaign_IDs(row.Alex_N_Campaign_IDs);
    setTempAndrei_L_Campaign_IDs(row.Andrei_L_Campaign_IDs);
    setTempMarina_C_Campaign_IDs(row.Marina_C_Campaign_IDs);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);
    setTempComments("");
    setTempArtem_Y_Campaign_IDs("");
    setTempVlad_A_Campaign_IDs("");
    setTempAlex_N_Campaign_IDs("");
    setTempAndrei_L_Campaign_IDs("");
    setTempMarina_C_Campaign_IDs("");
  };

  const handleSave = async () => {
    if (!selectedRow) return;

    const updatedData = {
      Source: selectedRow.Source,
      Category: selectedRow.Category,
      Partner: selectedRow.Partner,
      Geo: selectedRow.Geo,
      Artem_Y_Campaign_IDs: tempArtem_Y_Campaign_IDs,
      Vlad_A_Campaign_IDs: tempVlad_A_Campaign_IDs,
      Alex_N_Campaign_IDs: tempAlex_N_Campaign_IDs,
      Andrei_L_Campaign_IDs: tempAndrei_L_Campaign_IDs,
      Marina_C_Campaign_IDs: tempMarina_C_Campaign_IDs,
      Comments: tempComments,
      user_email: userEmail,
    };

    try {
      await axios.post(
        "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/selected_categories_update/",
        updatedData
      );

      const updatedRows = tableData.rows.map((r) =>
        r.Source === selectedRow.Source &&
        r.Category === selectedRow.Category &&
        r.Partner === selectedRow.Partner &&
        r.Geo === selectedRow.Geo
          ? {
              ...r,
              Comments: tempComments,
              Artem_Y_Campaign_IDs: tempArtem_Y_Campaign_IDs,
              Vlad_A_Campaign_IDs: tempVlad_A_Campaign_IDs,
              Alex_N_Campaign_IDs: tempAlex_N_Campaign_IDs,
              Andrei_L_Campaign_IDs: tempAndrei_L_Campaign_IDs,
              Marina_C_Campaign_IDs: tempMarina_C_Campaign_IDs,
            }
          : r
      );

      setTableData((prevState) => ({
        ...prevState,
        rows: updatedRows,
      }));

      toast.success("Comments and Campaign IDs updated successfully!");
    } catch (error) {
      console.error("Error updating data:", error.response.data);
      toast.error("Failed to update Comments and Campaign IDs!");
    }

    handleEditDialogClose();
  };

  const memoizedRows = useMemo(() => {
    // Функция для форматирования даты
    const formatDate = (dateStr) => {
      if (!dateStr) return new Date();
      const [day, month, year] = dateStr.split(".");
      const formattedDateStr = `${year}-${month}-${day}`;
      const date = new Date(formattedDateStr);
      if (isNaN(date.getTime())) {
        console.warn(`Invalid date format: ${dateStr}`);
        return new Date();
      }
      return date;
    };

    // Фильтрация и преобразование данных
    return tableData.rows
      .filter((row) => {
        // Status filter
        if (selectedStatus && selectedColumn !== "All") {
          if (row[selectedColumn] !== selectedStatus) return false;
        }

        // Date range filter
        const rowDate = formatDate(row.Date);
        if (startDate && endDate) {
          const start = formatDate(startDate);
          const end = formatDate(endDate);
          if (rowDate < start || rowDate > end) return false;
        }

        // Other filters
        const geoMatch = selectedGeo ? row.Geo === selectedGeo : true;
        const partnerMatch = selectedPartner ? row.Partner === selectedPartner : true;
        const categoryMatch = selectedCategory ? row.Category === selectedCategory : true;
        const searchMatch = Object.values(row).some((val) =>
          String(val).toLowerCase().includes(search.toLowerCase())
        );

        return geoMatch && partnerMatch && categoryMatch && searchMatch;
      })
      .map((row) => ({
        ...row,
        ArtemY: renderSelect(row, "ArtemY"),
        VladA: renderSelect(row, "VladA"),
        AntonC: renderSelect(row, "AntonC"),
        AlexN: renderSelect(row, "AlexN"),
        AndreiL: renderSelect(row, "AndreiL"),
        MarinaC: renderSelect(row, "MarinaC"),
        edit: (
          <IconButton onClick={() => handleEditClick(row)}>
            <EditIcon />
          </IconButton>
        ),
      }));
  }, [
    tableData.rows,
    search,
    selectedColumn,
    selectedStatus,
    startDate,
    endDate,
    selectedGeo,
    selectedPartner,
    selectedCategory,
    renderSelect,
    handleEditClick,
  ]);

  useEffect(() => {
    setFilteredData(
      tableData.rows.filter((row) =>
        Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
      )
    );
  }, [search, tableData.rows]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent="center" width="100%">
              <FilterCard
                selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                statuses={statuses}
                md_names={md_names}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent="center" width="100%">
              <DateRangeFilter onDateRangeChange={handleDateRangeChange} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent="center" width="100%">
              <FilterByGeoPartnerCategory
                selectedGeo={selectedGeo}
                setSelectedGeo={setSelectedGeo}
                geoOptions={geoOptions}
                selectedPartner={selectedPartner}
                setSelectedPartner={setSelectedPartner}
                partnerOptions={partnerOptions}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categoryOptions={categoryOptions}
              />
            </Box>
          </Grid>
        </Grid>

        <RequestButton
          url="https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/selected_categories_add_new/"
          buttonText="Add new data"
          loadingText="Loading..."
          tooltipText="Use this button to add new data from Google Sheets____Step 1: Add data to sheet(dont froget to remove old data) __[OPEN GOOGLE SHEET]____Step 2: Click this button____Step 3: Clean Google Sheet"
          redirectLink={
            "https://docs.google.com/spreadsheets/d/1IOP-B4-x056Xc6rBpxQGYo_AoSS5kLqYw5jeve8ijjQ/edit?gid=1667447458#gid=1667447458"
          }
        />

        <DataTable table={{ ...tableData, rows: memoizedRows }} entriesPerPage={false} canSearch />
      </MDBox>

      {/* Edit Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={handleEditDialogClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            width: "30%",
            height: "70%",
          },
        }}
      >
        <DialogTitle>Edit Comments & IDs</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} padding={2}>
            <MDInput
              label="Comments"
              value={tempComments}
              onChange={(e) => setTempComments(e.target.value)}
              fullWidth
              sx={{ height: "60px" }}
            />
            <MDInput
              label="Artem_Y Campaign IDs"
              value={tempArtem_Y_Campaign_IDs}
              onChange={(e) => setTempArtem_Y_Campaign_IDs(e.target.value)}
              fullWidth
              sx={{ height: "60px" }}
            />
            <MDInput
              label="Vlad_A Campaign IDs"
              value={tempVlad_A_Campaign_IDs}
              onChange={(e) => setTempVlad_A_Campaign_IDs(e.target.value)}
              fullWidth
              sx={{ height: "60px" }}
            />
            <MDInput
              label="Alex_N Campaign IDs"
              value={tempAlex_N_Campaign_IDs}
              onChange={(e) => setTempAlex_N_Campaign_IDs(e.target.value)}
              fullWidth
              sx={{ height: "60px" }}
            />
            <MDInput
              label="Andrei_L Campaign IDs"
              value={tempAndrei_L_Campaign_IDs}
              onChange={(e) => setTempAndrei_L_Campaign_IDs(e.target.value)}
              fullWidth
              sx={{ height: "60px" }}
            />
            <MDInput
              label="Marina_C Campaign IDs"
              value={tempMarina_C_Campaign_IDs}
              onChange={(e) => setTempMarina_C_Campaign_IDs(e.target.value)}
              fullWidth
              sx={{ height: "60px" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="center" width="100%">
            <Button onClick={handleEditDialogClose} sx={{ marginRight: 2 }}>
              Cancel
            </Button>
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default SelectedCategories;
