import React from "react";
import { TextField, InputAdornment, IconButton, Paper, Typography, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// eslint-disable-next-line react/prop-types
const SearchFieldAll = ({ search, onSearchChange }) => {
  return (
    <Paper sx={{ width: "100%", padding: 2, mb: 2 }}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2}>
        <Typography variant="h6">Search</Typography>
        <TextField
          label="Search Filter..."
          variant="outlined"
          fullWidth
          value={search}
          onChange={onSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Paper>
  );
};

export default SearchFieldAll;
