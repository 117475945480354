import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
} from "@mui/material";
import MDInput from "../../../components/MDInput";
import SaveIcon from "@mui/icons-material/Save";

const EditDialog = ({ openEditDialog, handleEditDialogClose, selectedRow, handleSave }) => {
  const today = new Date();
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const defaultDate = tomorrow.toISOString().split("T")[0]; // 'YYYY-MM-DD' format

  const [date, setDate] = useState(defaultDate);
  const [time, setTime] = useState("10:00:00");

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleSaveClick = () => {
    const activationDateTime = `${date} ${time}`;
    console.log("Activation Date & Time:", activationDateTime);
    handleSave(activationDateTime);
  };

  // Helper function to format date as a string
  const formatDate = (date, timeZone) => {
    const dateObj = new Date(`${date}T${time}`);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone,
    };
    return new Intl.DateTimeFormat("en-US", options).format(dateObj);
  };

  // Los Angeles time (DA provider)
  const laTime = formatDate(date, "America/Los_Angeles");

  // UTC time (S1 provider)
  const utcTime = formatDate(date, "UTC");

  return (
    <Dialog
      open={openEditDialog}
      onClose={handleEditDialogClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          width: "35%",
          height: "auto",
        },
      }}
    >
      <DialogTitle sx={{ mb: 3 }}>Schedule campaign activation</DialogTitle>
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Campaign ID:
        </Typography>
        <Typography variant="h6" sx={{ mb: 2, color: "grey" }}>
          {selectedRow?.camp_id}
        </Typography>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Campaign Name:
        </Typography>
        <Typography variant="h6" sx={{ mb: 2, color: "grey" }}>
          {selectedRow?.campaign_name}
        </Typography>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Campaign Status:
        </Typography>
        <Typography variant="h6" sx={{ mb: 4, color: "grey" }}>
          {selectedRow?.campaign_status}
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Select activation Date & Time:
        </Typography>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 5 }}>
          <MDInput type="date" label="Date" value={date} onChange={handleDateChange} />
          <MDInput type="time" label="Time" value={time} onChange={handleTimeChange} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">DA provider time: </Typography>
          <Typography variant="h6" sx={{ mb: 4, color: "grey" }}>
            {laTime}
          </Typography>
          <Typography variant="h6">S1 provider time: </Typography>
          <Typography variant="h6" sx={{ color: "grey" }}>
            {utcTime}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button onClick={handleEditDialogClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSaveClick} startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

// Define PropTypes for EditDialog
EditDialog.propTypes = {
  openEditDialog: PropTypes.bool.isRequired,
  handleEditDialogClose: PropTypes.func.isRequired,
  selectedRow: PropTypes.shape({
    camp_id: PropTypes.string,
    campaign_name: PropTypes.string,
    campaign_status: PropTypes.string,
  }),
  handleSave: PropTypes.func.isRequired,
};

export default EditDialog;
