import React, { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "../../../examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDTypography from "../../../components/MDTypography";
import Card from "@mui/material/Card";
import { useFilteredData, useTableDataAdsetQueue } from "../../dashboards/rules/adset_queue";

const scrollStyle = {
  maxHeight: "200px",
  maxWidth: "200px",
  overflowY: "auto",
};

const tableTips = [
  "This table is used to display campaigns that are scheduled for launch.",
  "If the table is empty, it means there are no upcoming campaigns.",
];

const tableTips2 = [
  "This table is used to display adsets that are scheduled for launch.",
  "If the table is empty, it means there are no upcoming adsets.",
];

const renderTips = (tipsList) => {
  return tipsList.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });
};

function CampaignsUnderRuleQueue() {
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "update_time", accessor: "update_time", width: "10%" },
      { Header: "activate_time", accessor: "activate_time", width: "10%" },
      { Header: "activate_provider_time", accessor: "activate_provider_time", width: "10%" },
      { Header: "paused_time", accessor: "paused_time", width: "10%" },
      {
        Header: "campaign Name",
        accessor: "campaign_name",
        width: "10%",
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => <div style={scrollStyle}>{value}</div>,
      },
      { Header: "account_id", accessor: "account_id", width: "10%" },
      { Header: "status", accessor: "status", width: "10%" },
      { Header: "reason", accessor: "reason", width: "10%" },
      { Header: "provider", accessor: "provider", width: "10%" },
      { Header: "paused_count", accessor: "paused_count", width: "10%" },
      { Header: "camp_id", accessor: "camp_id", width: "10%" },
      { Header: "Spend", accessor: "Spend", width: "10%" },
      { Header: "Revenue_f", accessor: "Revenue_f", width: "10%" },
      { Header: "Revenue_a", accessor: "Revenue_a", width: "10%" },
      { Header: "ROI_f", accessor: "ROI_f", width: "10%" },
      { Header: "ROI", accessor: "ROI", width: "10%" },
      { Header: "Profit_f", accessor: "Profit_f", width: "10%" },
      { Header: "Profit", accessor: "Profit", width: "10%" },
      { Header: "rule_id", accessor: "rule_id", width: "10%" },
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const tableDataAdsetQueue = useTableDataAdsetQueue();
  const filteredDataQueue = useFilteredData(tableDataAdsetQueue, search);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/campaigns_under_rule_queue/"
        );
        const rows = response.data.map((rule) => ({
          update_time: rule.update_time,
          status: rule.status,
          rule_id: rule.rule_id,
          reason: (
            <pre>
              {rule.reason.split(", ").map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
            </pre>
          ),
          provider: rule.provider,
          paused_time: rule.paused_time,
          paused_count: rule.paused_count,
          campaign_name: rule.campaign_name,
          account_id: rule.account_id,
          camp_id: rule.camp_id,
          activate_time: rule.activate_time,
          activate_provider_time: rule.activate_provider_time,
          // Spend: rule.Spend,
          // Revenue_a: rule.Revenue_a,
          // Revenue_f: rule.Revenue_f,
          // ROI: rule.ROI,
          // ROI_f: rule.ROI_f,
          // Profit_f: rule.Profit_f,
          // Profit: rule.Profit,
          Spend: parseFloat(rule.Spend).toFixed(2),
          Revenue_a: parseFloat(rule.Revenue_a).toFixed(2),
          Revenue_f: parseFloat(rule.Revenue_f).toFixed(2),
          ROI: parseFloat(rule.ROI).toFixed(2),
          ROI_f: parseFloat(rule.ROI_f).toFixed(2),
          Profit_f: parseFloat(rule.Profit_f).toFixed(2),
          Profit: parseFloat(rule.Profit).toFixed(2),
        }));
        setTableData((prevState) => ({ ...prevState, rows }));
        setFilteredData(rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredData(
      tableData.rows.filter((row) =>
        Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
      )
    );
  }, [search, tableData.rows]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Table 1</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(tableTips)}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData} canSearch entriesPerPage={false} />
            </Paper>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Table 2</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(tableTips2)}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableDataAdsetQueue} canSearch entriesPerPage={false} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CampaignsUnderRuleQueue;
