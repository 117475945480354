import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import axios from "axios";

function SignIn({ setIsLoggedIn, setUserEmail, setUserRole }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loggedInState, setLoggedInState] = useState(false);
  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignIn = async () => {
    try {
      const response = await axios.post(
        "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/sign_in/",
        {
          email,
          password,
        }
      );
      setUserEmail(email);
      setUserRole(response.data.role);
      console.log("Response data:", response.data);

      if (response.data.message === "Sign in successful") {
        setIsLoggedIn(true);
        setLoggedInState(true);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userEmail", email);
        localStorage.setItem("userRole", response.data.role);
        console.log("isLoggedIn after:", true);
        console.log("userEmail:", email);
        console.log("useRole:", response.data.role);
        navigate("/applications/calendar");
      } else if (response.data.message === "Sign in failed") {
        setIsLoggedIn(false);
        setPasswordError(true);
        setPassword("");
      }
    } catch (error) {
      console.error("Error signing in:", error);
      setPasswordError(true);
    }
  };

  useEffect(() => {
    console.log("isLoggedIn:", loggedInState);
  }, [loggedInState]);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Login"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                error={passwordError}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(false);
                }}
              />
            </MDBox>
            {passwordError && (
              <MDTypography variant="body2" color="error">
                Incorrect email or password. Please try again.
              </MDTypography>
            )}
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSignIn}>
                Sign in
              </MDButton>
              {/*<MDTypography variant="button" color="text">*/}
              {/*  Don&apos;t have an account?{" "}*/}
              {/*  <MDTypography*/}
              {/*    component={Link}*/}
              {/*    to="/authentication/sign-up/cover"*/}
              {/*    variant="button"*/}
              {/*    color="info"*/}
              {/*    fontWeight="medium"*/}
              {/*    textGradient*/}
              {/*  >*/}
              {/*    Sign up*/}
              {/*  </MDTypography>*/}
              {/*</MDTypography>*/}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

SignIn.propTypes = {
  setIsLoggedIn: PropTypes.func.isRequired,
  setUserEmail: PropTypes.func.isRequired,
  setUserRole: PropTypes.func.isRequired,
};

export default SignIn;
