import React, { useState } from "react";
import axios from "axios";
import { Button, CircularProgress, Box, Tooltip, Link } from "@mui/material";
import { toast } from "react-toastify";

// eslint-disable-next-line react/prop-types
const RequestButton = ({ url, buttonText, loadingText, tooltipText, redirectLink }) => {
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    console.log("Button clicked");
    setLoading(true);
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        // Обработка успешного ответа
        console.log("Request succeeded:", response.data);
        toast.success("New data added successfully!");
        window.location.reload();
      } else {
        // Обработка ошибки
        console.error("Request failed with status:", response.status);
        toast.error("Failed to add data from GoogleSheet!");
      }
    } catch (error) {
      // Обработка ошибки
      console.error("Error making request:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" alignItems="center" pb={3}>
      <Tooltip
        title={
          <React.Fragment>
            {/* eslint-disable-next-line react/prop-types */}
            {tooltipText.split("__").map((line, index) => (
              <React.Fragment key={index}>
                {line.includes("[OPEN GOOGLE SHEET]") ? (
                  <Link
                    key={index}
                    href={redirectLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                  >
                    {line.replace("[OPEN GOOGLE SHEET]", "OPEN GOOGLE SHEET")}
                  </Link>
                ) : (
                  <span key={index}>{line}</span>
                )}
                <br />
              </React.Fragment>
            ))}
          </React.Fragment>
        }
        arrow
        enterDelay={100} // Установите задержку появления подсказки, если нужно
      >
        <span>
          <Button
            variant="contained"
            color="white" // Измените цвет кнопки, если нужно
            onClick={handleButtonClick}
            disabled={loading}
            sx={{ marginRight: "16px" }} // Убедитесь, что текст белый
          >
            {loading ? loadingText : buttonText}
          </Button>
        </span>
      </Tooltip>
      {loading && <CircularProgress size={24} />}
    </Box>
  );
};

export default RequestButton;
