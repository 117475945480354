import React, { useEffect } from "react";
import { Box, Typography, FormControl, TextField, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

const CustomInputFieldMultiline = ({ inputValue, setInputValue, label, multiline, required }) => {
  const [touched, setTouched] = React.useState(false);

  useEffect(() => {
    if (required) {
      setTouched(true);
    }
  }, [required]);

  const handleBlur = () => {
    setTouched(true);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mb={1}
      sx={{
        gap: 2,
        width: "100%",
      }}
    >
      <FormControl
        variant="standard"
        sx={{ width: "100%" }}
        required={required}
        error={required && touched && !inputValue}
      >
        <TextField
          value={inputValue}
          label={label}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleBlur}
          multiline={multiline}
          rows={multiline ? 3 : 1}
          sx={{ width: "100%" }}
        />
        {required && touched && !inputValue && (
          <FormHelperText>Please enter {label.toLowerCase()}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

CustomInputFieldMultiline.propTypes = {
  inputValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
};

CustomInputFieldMultiline.defaultProps = {
  multiline: true,
};

export default CustomInputFieldMultiline;
