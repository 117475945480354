import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import AccountCheckboxList from "./data_accounts";
import CampaignCheckboxList from "./data_campaigns";
import { useTableDataAdsetLogs, useFilteredData, adsetLogsTips } from "./adsets_logs";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import DialogFormAds from "./ads_dialog";

function AdsRule() {
  const [tableData1, setTableData1] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "30%" },
      { Header: "condition", accessor: "condition", disableSortBy: true, width: "59%" },
      { Header: "action", accessor: "action", disableSortBy: true, width: "10%" },
      { Header: "EDIT", accessor: "edit", disableSortBy: true, width: "1%" },
    ],
    rows: [],
  });

  const [tableData2, setTableData2] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "30%" },
      { Header: "condition", accessor: "condition", disableSortBy: true, width: "58%" },
      { Header: "action", accessor: "action", disableSortBy: true, width: "10%" },
      { Header: "EDIT", accessor: "edit", disableSortBy: true, width: "2%" },
    ],
    rows: [],
  });
  const [search, setSearch] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [tempValue_1, setTempValue_1] = useState("");
  const [tempValue_1_USER1, setTempValue_1_USER1] = useState("");
  const [tempValue_1_USER2, setTempValue_1_USER2] = useState("");
  const [tempValue_1_USER3, setTempValue_1_USER3] = useState("");
  const [tempValue_1_USER4, setTempValue_1_USER4] = useState("");
  const [tempValue_1_USER5, setTempValue_1_USER5] = useState("");
  const [tempValue_2, setTempValue_2] = useState("");
  const [tempValue_2_USER1, setTempValue_2_USER1] = useState("");
  const [tempValue_2_USER2, setTempValue_2_USER2] = useState("");
  const [tempValue_2_USER3, setTempValue_2_USER3] = useState("");
  const [tempValue_2_USER4, setTempValue_2_USER4] = useState("");
  const [tempValue_2_USER5, setTempValue_2_USER5] = useState("");

  const [tempNotifyOnly, setTempNotifyOnly] = useState("");
  const [tempNotifyOnly_USER1, setTempNotifyOnly_USER1] = useState("");
  const [tempNotifyOnly_USER2, setTempNotifyOnly_USER2] = useState("");
  const [tempNotifyOnly_USER3, setTempNotifyOnly_USER3] = useState("");
  const [tempNotifyOnly_USER4, setTempNotifyOnly_USER4] = useState("");
  const [tempNotifyOnly_USER5, setTempNotifyOnly_USER5] = useState("");

  const [ruleActivationHour1, setRuleActivationHour1] = useState("");
  const [ruleActivationHour2, setRuleActivationHour2] = useState("");
  const [ruleInterval1, setRuleInterval1] = useState("");
  const [ruleInterval2, setRuleInterval2] = useState("");

  const tableDataAdsetLogs = useTableDataAdsetLogs("ads_s");
  const filteredDataLogs = useFilteredData(tableDataAdsetLogs, search);

  const userNames = {
    "Artemyusi@gmail.com": "USER1",
    "Vlad0986613794@gmail.com": "USER2",
    "nuzhnyias@gmail.com": "USER3",
    "andreylipovskiy0@gmail.com": "USER4",
    "mcerneva2@gmail.com": "USER5",
  };

  const ruleTips1 = [
    "To disable a rule, turn it off for all accounts or use the toggle.",
    "If you disable the accounts or campaigns listed below, this will apply to all rules in this set.",
    "When you edit a rule, you're only editing it for yourself; it won't affect other people's campaigns.",
    "Set applies to campaigns that have L2 in the campaign name (ad set level).",
    "The rule can be applied from 00:00 to 14:00.",
  ];
  const ruleTips2 = [
    "To disable a rule, turn it off for all accounts or use the toggle.",
    "If you disable the accounts or campaigns listed below, this will apply to all rules in this set.",
    "When you edit a rule, you're only editing it for yourself; it won't affect other people's campaigns.",
    "Set applies to campaigns that have L2 in the campaign name (ad set level).",
  ];

  const Tips = ["Leave blank to use the default value.", "Do not use spaces"];

  const renderTips = (tipsList) => {
    return tipsList.map((item, key) => {
      const itemKey = `element-${key}`;

      return (
        <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
            {item}
          </MDTypography>
        </MDBox>
      );
    });
  };

  const API_URL = "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app";

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");
  const userName = userNames[userEmail];

  // const generateCondition = (rule, userRole, userName) => {
  //   if (userRole === "admin") {
  //     return (
  //       <pre>
  //         <div style={{ display: "flex", alignItems: "center" }}>
  //           {rule.condition1}
  //           {rule.value_1 && rule.value_1 !== "None" && `${rule.value_1}`}
  //           {rule.condition1_1}
  //           {rule.value_2 && rule.value_2 !== "None" && `${rule.value_2}`}
  //           {rule.condition2}
  //         </div>
  //       </pre>
  //     );
  //   } else {
  //     return (
  //       <pre>
  //         <div style={{ display: "flex", alignItems: "center" }}>
  //           {rule.condition1}
  //           {rule[`value_1_${userName}`]}
  //           {rule.condition1_1}
  //           {rule[`value_2_${userName}`]}
  //           {rule.condition2}
  //         </div>
  //       </pre>
  //     );
  //   }
  // };
  const generateCondition = (rule, userRole, userName) => {
    // Функция для разделения текста на части с максимальной длиной n символов, не разрывая слова
    const splitTextByWords = (text, maxLength) => {
      const words = text.split(" ");
      const lines = [];
      let currentLine = "";

      words.forEach((word) => {
        if ((currentLine + word).length <= maxLength) {
          currentLine += `${word} `;
        } else {
          lines.push(currentLine.trim());
          currentLine = `${word} `;
        }
      });

      if (currentLine.trim()) {
        lines.push(currentLine.trim());
      }

      return lines;
    };

    // Создание дескрипшена
    const description =
      userRole === "admin"
        ? `${rule.condition1}${rule.value_1 || ""}${rule.condition1_1 || ""}${rule.value_2 || ""} ${
            rule.condition2 || ""
          }`
        : `${rule.condition1}${rule[`value_1_${userName}`] || ""}${rule.condition1_1 || ""}${
            rule[`value_2_${userName}`] || ""
          } ${rule.condition2 || ""}`;

    // Ограничение на количество символов
    const maxLength = 70; // Пример ограничения по количеству символов
    const splitDescription = splitTextByWords(description, maxLength);

    return (
      <pre>
        {splitDescription.map((part, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            {part}
          </div>
        ))}
      </pre>
    );
  };

  const generateAction = (rule, userRole, userName) => {
    if (userRole === "admin") {
      return (
        <pre>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
              alt="Red Arrow"
              style={{ width: "24px", height: "24px", marginRight: "20px" }}
            />
            {rule.action}
          </div>
        </pre>
      );
    } else {
      return (
        <pre>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
              alt="Red Arrow"
              style={{ width: "24px", height: "24px", marginRight: "20px" }}
            />
            {rule.action}
          </div>
        </pre>
      );
    }
  };

  const handleSave = async () => {
    if (!selectedRow) return;
    const updatedData = {
      rule_name: selectedRow.rule_name,
      rule_id: selectedRow.rule_id,
      value_1: tempValue_1,
      value_1_USER1: tempValue_1_USER1,
      value_1_USER2: tempValue_1_USER2,
      value_1_USER3: tempValue_1_USER3,
      value_1_USER4: tempValue_1_USER4,
      value_1_USER5: tempValue_1_USER5,
      value_2: tempValue_2,
      value_2_USER1: tempValue_2_USER1,
      value_2_USER2: tempValue_2_USER2,
      value_2_USER3: tempValue_2_USER3,
      value_2_USER4: tempValue_2_USER4,
      value_2_USER5: tempValue_2_USER5,
      notify_only: tempNotifyOnly,
      notify_only_USER1: tempNotifyOnly_USER1,
      notify_only_USER2: tempNotifyOnly_USER2,
      notify_only_USER3: tempNotifyOnly_USER3,
      notify_only_USER4: tempNotifyOnly_USER4,
      notify_only_USER5: tempNotifyOnly_USER5,
      user_email: userEmail,
    };
    try {
      await axios.post(`${API_URL}/api/rules/update_rule_ads/`, updatedData);
      await fetchData();
      toast.success("Data updated successfully!");
      handleEditDialogClose(); // Close the dialog after saving
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response1 = await axios.post(`${API_URL}/api/rules/ads/separated/`, {
        rule_ids: ["8", "9", "11", "12", "13"],
      });
      const response2 = await axios.post(`${API_URL}/api/rules/ads/separated/`, {
        rule_ids: ["1", "2", "3", "4", "5", "6", "7", "10"],
      });

      const rows1 = response1.data.map((rule) => {
        if (rule.rule_name === "ads_stop_8") {
          setRuleActivationHour1(
            userRole === "admin"
              ? rule.first_set_activation_hour
              : rule[`first_set_activation_hour_${userName}`]
          );
          setRuleInterval1(
            userRole === "admin" ? rule.set_run_interval : rule[`set_run_interval_${userName}`]
          );
        }
        const conditionContent = generateCondition(rule, userRole, userName);
        const actionContent = generateAction(rule, userRole, userName);

        return {
          rule_id: rule.rule_id,
          value_1: rule.value_1,
          value_2: rule.value_2,
          rule_name: (
            <div>
              {/* Глобальный статус */}
              <FormControlLabel
                control={
                  <Switch
                    checked={rule.status}
                    onChange={() => handleToggle(rule.rule_id, "status", rule.status)}
                    disabled={userRole !== "admin"}
                  />
                }
                label={`${rule.rule_name}`}
              />

              {/* Статус для обычных пользователей */}
              {userRole !== "admin" && userNames[userEmail] && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={rule[`status_${userNames[userEmail]}`]}
                      onChange={() =>
                        handleToggle(
                          rule.rule_id,
                          `status_${userNames[userEmail]}`,
                          rule[`status_${userNames[userEmail]}`]
                        )
                      }
                    />
                  }
                  label={`${userNames[userEmail]} (${
                    rule[`status_${userNames[userEmail]}`] ? "Active" : "Paused"
                  })`}
                />
              )}
            </div>
          ),
          condition: conditionContent,
          action: actionContent,
          edit: (
            <IconButton onClick={() => handleEditClick(rule)} aria-label="edit" size="large">
              <EditIcon />
            </IconButton>
          ),
        };
      });

      const rows2 = response2.data.map((rule) => {
        if (rule.rule_name === "ads_stop_1") {
          setRuleActivationHour2(
            userRole === "admin"
              ? rule.first_set_activation_hour
              : rule[`first_set_activation_hour_${userName}`]
          );
          setRuleInterval2(
            userRole === "admin" ? rule.set_run_interval : rule[`set_run_interval_${userName}`]
          );
        }
        const conditionContent = generateCondition(rule, userRole, userName);
        const actionContent = generateAction(rule, userRole, userName);

        return {
          rule_id: rule.rule_id,
          value_1: rule.value_1,
          value_2: rule.value_2,
          rule_name: (
            <div>
              {/* Глобальный статус */}
              <FormControlLabel
                control={
                  <Switch
                    checked={rule.status}
                    onChange={() => handleToggle(rule.rule_id, "status", rule.status)}
                    disabled={userRole !== "admin"}
                  />
                }
                label={`${rule.rule_name}`}
              />

              {/* Статус для обычных пользователей */}
              {userRole !== "admin" && userNames[userEmail] && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={rule[`status_${userNames[userEmail]}`]}
                      onChange={() =>
                        handleToggle(
                          rule.rule_id,
                          `status_${userNames[userEmail]}`,
                          rule[`status_${userNames[userEmail]}`]
                        )
                      }
                    />
                  }
                  label={`${userNames[userEmail]} (${
                    rule[`status_${userNames[userEmail]}`] ? "Active" : "Paused"
                  })`}
                />
              )}
            </div>
          ),
          condition: conditionContent,
          action: actionContent,
          edit: (
            <IconButton onClick={() => handleEditClick(rule)} aria-label="edit" size="large">
              <EditIcon />
            </IconButton>
          ),
        };
      });

      setTableData1((prevState) => ({ ...prevState, rows: rows1 }));
      setTableData2((prevState) => ({ ...prevState, rows: rows2 }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const userValue_1 = {
    USER1: tempValue_1_USER1,
    USER2: tempValue_1_USER2,
    USER3: tempValue_1_USER3,
    USER4: tempValue_1_USER4,
    USER5: tempValue_1_USER5,
  };

  const userValue_2 = {
    USER1: tempValue_2_USER1,
    USER2: tempValue_2_USER2,
    USER3: tempValue_2_USER3,
    USER4: tempValue_2_USER4,
    USER5: tempValue_2_USER5,
  };

  const userNotifyOnly = {
    USER1: tempNotifyOnly_USER1,
    USER2: tempNotifyOnly_USER2,
    USER3: tempNotifyOnly_USER3,
    USER4: tempNotifyOnly_USER4,
    USER5: tempNotifyOnly_USER5,
  };

  const setFunctions = {
    USER1: {
      setValue_1: setTempValue_1_USER1,
      setValue_2: setTempValue_2_USER1,
      setUserNotifyOnly: setTempNotifyOnly_USER1,
    },
    USER2: {
      setValue_1: setTempValue_1_USER2,
      setValue_2: setTempValue_2_USER2,
      setUserNotifyOnly: setTempNotifyOnly_USER2,
    },
    USER3: {
      setValue_1: setTempValue_1_USER3,
      setValue_2: setTempValue_2_USER3,
      setUserNotifyOnly: setTempNotifyOnly_USER3,
    },
    USER4: {
      setValue_1: setTempValue_1_USER4,
      setValue_2: setTempValue_2_USER4,
      setUserNotifyOnly: setTempNotifyOnly_USER4,
    },
    USER5: {
      setValue_1: setTempValue_1_USER5,
      setValue_2: setTempValue_2_USER5,
      setUserNotifyOnly: setTempNotifyOnly_USER5,
    },
  };

  const handleValueChange = (field, value) => {
    if (userRole === "admin") {
      switch (field) {
        case "Value_1":
          setTempValue_1(value);
          break;
        case "Value_2":
          setTempValue_2(value);
          break;
        case "setNotifyOnly":
          setTempNotifyOnly(value);
          break;
        default:
          break;
      }
    } else {
      const userSetFunctions = setFunctions[userName];
      if (userSetFunctions) {
        switch (field) {
          case "Value_1":
            userSetFunctions.setValue_1(value);
            break;
          case "Value_2":
            userSetFunctions.setValue_2(value);
            break;
          case "setNotifyOnly":
            userSetFunctions.setUserNotifyOnly(value);
            break;
          default:
            break;
        }
      }
    }
  };

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);

    setTempValue_1(row.value_1);
    setTempValue_2(row.value_2);

    setTempValue_1_USER1(row.value_1_USER1);
    setTempValue_2_USER1(row.value_2_USER1);

    setTempValue_1_USER2(row.value_1_USER2);
    setTempValue_2_USER2(row.value_2_USER2);

    setTempValue_1_USER3(row.value_1_USER3);
    setTempValue_2_USER3(row.value_2_USER3);

    setTempValue_1_USER4(row.value_1_USER4);
    setTempValue_2_USER4(row.value_2_USER4);

    setTempValue_1_USER5(row.value_1_USER5);
    setTempValue_2_USER5(row.value_2_USER5);

    setTempNotifyOnly(row.notify_only);
    setTempNotifyOnly_USER1(row.notify_only_USER1);
    setTempNotifyOnly_USER2(row.notify_only_USER2);
    setTempNotifyOnly_USER3(row.notify_only_USER3);
    setTempNotifyOnly_USER4(row.notify_only_USER4);
    setTempNotifyOnly_USER5(row.notify_only_USER5);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);

    setTempValue_1("");
    setTempValue_2("");

    setTempValue_1_USER1("");
    setTempValue_2_USER1("");

    setTempValue_1_USER2("");
    setTempValue_2_USER2("");

    setTempValue_1_USER3("");
    setTempValue_2_USER3("");

    setTempValue_1_USER4("");
    setTempValue_2_USER4("");

    setTempValue_1_USER5("");
    setTempValue_2_USER5("");

    setTempNotifyOnly("");
    setTempNotifyOnly_USER1("");
    setTempNotifyOnly_USER2("");
    setTempNotifyOnly_USER3("");
    setTempNotifyOnly_USER4("");
  };

  const handleToggle = useCallback(async (ruleId = "", columnName, currentStatus) => {
    const newStatus = currentStatus === true ? "Inactive" : "Active";
    console.log("Current status/new status:", currentStatus, newStatus);

    // Send request to update rule status
    const url = `${API_URL}/api/rules_ads/update_status/`;

    try {
      console.log("ruleId/columnName/newStatus:", ruleId, columnName, newStatus);
      await axios.post(url, {
        rule_id: ruleId,
        column_name: columnName,
        status: newStatus,
        user_email: userEmail,
      });
      // Show success toast after successful update
      toast.success(`Rule status for ID ${ruleId} updated!`);
      await fetchData();
    } catch (error) {
      console.error("Error toggling rule status:", error.response.data);
      toast.error(`Failed to update rule status for ID ${ruleId}.`);
    }
  }, []);

  const handleSubmitForm1 = async () => {
    const data = {
      rule_ids: ["8", "9", "11", "12", "13"],
      column_name_activation_hour:
        userRole === "admin"
          ? "first_set_activation_hour"
          : `first_set_activation_hour_${userName}`,
      first_activation_hour: ruleActivationHour1,
      task_name: "first_activation_hour",
    };

    try {
      const response = await axios.post(
        `${API_URL}/api/rules/update_interval_or_activation_hour_ads/`,
        data
      );
      console.log("Response:", response.data);
      toast.success(`Success!`);
    } catch (error) {
      console.error("Error updating rule activation hour 1:", error);
      toast.error(`Failed to update hour`);
    }
  };

  const handleSubmitForm2 = async () => {
    const data = {
      rule_ids: ["1", "2", "3", "4", "5", "6", "7", "10"],
      column_name_activation_hour:
        userRole === "admin"
          ? "first_set_activation_hour"
          : `first_set_activation_hour_${userName}`,
      column_name_interval:
        userRole === "admin" ? "set_run_interval" : `set_run_interval_${userName}`,
      first_activation_hour: ruleActivationHour2,
      interval: ruleInterval2,
      task_name: "interval_and_first_activation_hour",
    };

    try {
      const response = await axios.post(
        `${API_URL}/api/rules/update_interval_or_activation_hour_ads/`,
        data
      );
      console.log("Response:", response.data);
      toast.success(`Success!`);
    } catch (error) {
      console.error("Error updating rule activation hour 2 and interval:", error);
      toast.error(`Failed to update hour`);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Set_1</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips1)}
              </MDBox>
            </MDBox>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1} ml={2}>
              <span
                style={{
                  display: "list-item",
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                Rule will run once a day at (hour in 24-hour format, Estonia time):&nbsp;&nbsp;
              </span>
              <TextField
                value={ruleActivationHour1}
                onChange={(e) => setRuleActivationHour1(e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "2%" }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                :15&nbsp;&nbsp;
              </span>
              <Button
                onClick={handleSubmitForm1}
                startIcon={<SaveIcon />}
                style={{
                  marginLeft: "2px",
                  color: "black",
                }}
              >
                Save
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData1} entriesPerPage={false} pagination={false} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Set_2</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips2)}
              </MDBox>
            </MDBox>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1} ml={2}>
              <span
                style={{
                  display: "list-item",
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                First rule run starts at (hour in 24-hour format, Estonia time)&nbsp;&nbsp;
              </span>
              <TextField
                value={ruleActivationHour2}
                onChange={(e) => setRuleActivationHour2(e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "2%" }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                &nbsp;&nbsp;with a repeat interval of (1 or 2)&nbsp;&nbsp;
              </span>
              <TextField
                value={ruleInterval2}
                onChange={(e) => setRuleInterval2(e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "2%" }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                &nbsp;&nbsp;hours
              </span>
              <Button
                onClick={handleSubmitForm2}
                startIcon={<SaveIcon />}
                style={{
                  marginLeft: "2px",
                  color: "black",
                }}
              >
                Save
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData2} entriesPerPage={false} pagination={false} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 1 }}>
              <AccountCheckboxList
                ruleName="Ads"
                fetchApiEndpoint={`${API_URL}/api/rules/get_accounts_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 1 }}>
              <CampaignCheckboxList
                ruleName="Ads"
                fetchApiEndpoint={`${API_URL}/api/rules/get_campaigns_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Logs</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(adsetLogsTips)}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%" }}>
              <DataTable table={tableDataAdsetLogs} canSearch entriesPerPage={false} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* Edit Dialog */}
      <DialogFormAds
        openEditDialog={openEditDialog}
        handleEditDialogClose={handleEditDialogClose}
        handleSave={handleSave}
        selectedRow={selectedRow}
        userRole={userRole}
        userName={userName}
        tempValue_1={tempValue_1}
        tempValue_2={tempValue_2}
        userValue_1={userValue_1}
        userValue_2={userValue_2}
        Tips={renderTips(Tips)}
        tempNotifyOnly={tempNotifyOnly}
        userNotifyOnly={userNotifyOnly}
        handleValueChange={handleValueChange}
      />
    </DashboardLayout>
  );
}

export default AdsRule;
