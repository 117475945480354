import React, { useState } from "react";
import { Box, TextField, Button, Typography, Paper } from "@mui/material";
import MDInput from "../../components/MDInput";

// eslint-disable-next-line react/prop-types
const DateRangeFilter2 = ({ onDateRangeChange, columnName }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleApplyClick = () => {
    // Передача данных в родительский компонент для применения фильтра
    onDateRangeChange({ startDate, endDate });
  };

  const handleClearClick = () => {
    setStartDate("");
    setEndDate("");
    onDateRangeChange({ startDate: "", endDate: "" });
  };

  return (
    <Paper sx={{ width: "100%", padding: 2, mb: 2 }}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} mb={1.3}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Filter by {columnName}
        </Typography>
        {/*<MDInput*/}
        {/*  label="Start Date"*/}
        {/*  type="date"*/}
        {/*  value={startDate}*/}
        {/*  onChange={(e) => setStartDate(e.target.value)}*/}
        {/*  fullWidth*/}
        {/*  InputLabelProps={{ shrink: true }}*/}
        {/*/>*/}
        {/*<MDInput*/}
        {/*  label="End Date"*/}
        {/*  type="date"*/}
        {/*  value={endDate}*/}
        {/*  onChange={(e) => setEndDate(e.target.value)}*/}
        {/*  fullWidth*/}
        {/*  InputLabelProps={{ shrink: true }}*/}
        {/*/>*/}
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <Box display="flex" justifyContent="center" gap={2} mt={0}>
          <Button variant="contained" color="white" size="small" onClick={handleApplyClick}>
            Apply
          </Button>
          <Button variant="contained" color="white" size="small" onClick={handleClearClick}>
            Clear
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default DateRangeFilter2;
