import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Select, MenuItem, IconButton } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MDInput from "../../../components/MDInput";
import Box from "@mui/material/Box";
import FilterCard from "../filter_card_name_status";
import DateRangeFilter from "../filter_by_date";
import FilterByColumnNameMinMax from "../filter_by_column_tsk";
import FilterByColumnNameSearch from "../search_by_column_name";
import SearchFieldAll from "../search_all_table";

function SelectedCategoriesAnalysis() {
  // убрать эту шестеренку
  useEffect(() => {
    const element = document.querySelector(".MuiBox-root.css-fxid9l");
    if (element) {
      element.remove();
    }
  }, []);

  const [tableData, setTableData] = useState({
    columns: [
      {
        Header: "Started On",
        accessor: "started_on",
      },
      {
        Header: "parse_date",
        accessor: "parse_date",
      },
      {
        Header: "search_url",
        accessor: "search_url",
        height: "200px",
        isUrl: true,
      },
      {
        Header: "parse_url",
        accessor: "parse_url",
        height: "200px",
        isUrl: true,
        urlParam: "view_all_page_id",
      },
      { Header: "status", accessor: "status" },
      { Header: "shows", accessor: "shows" },
      { Header: "amount", accessor: "amount" },
      { Header: "days_running", accessor: "days_running" },
      { Header: "avg_daily", accessor: "avg_daily" },
      {
        Header: "fb_lib_link",
        accessor: "fb_lib_link",
        height: "200px",
        isUrl: true,
      },
      {
        Header: "fb_library_id",
        accessor: "fb_library_id",
      },
      { Header: "Artem_Y", accessor: "Artem_Y", disableSortBy: true },
      { Header: "Vlad_A", accessor: "Vlad_A", disableSortBy: true },
      { Header: "Anton_C", accessor: "Anton_C", disableSortBy: true },
      { Header: "Alex_N", accessor: "Alex_N", disableSortBy: true },
      { Header: "Campaign_IDs", accessor: "CampaignIDs" },
      {
        Header: "Artem_Y_comment",
        accessor: "Artem_Y_comment",
        height: "200px",
      },
      {
        Header: "Vlad_A_comment",
        accessor: "Vlad_A_comment",
        height: "200px",
      },
      {
        Header: "Anton_C_comment",
        accessor: "Anton_C_comment",
        height: "200px",
      },
      {
        Header: "Alex_N_comment",
        accessor: "Alex_N_comment",
        height: "200px",
      },
      { Header: "EDIT", accessor: "edit", disableSortBy: true },
    ],
    rows: [],
  });

  const statuses = ["Launched", "Preparing", "Not Used", "Denied", "Whitelisted"];
  const md_names = ["Artem_Y", "Vlad_A", "Anton_C", "Alex_N"];
  const userEmail = localStorage.getItem("userEmail");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tempComments_Artem_Y, setTempComments_Artem_Y] = useState("");
  const [tempComments_Vlad_A, setTempComments_Vlad_A] = useState("");
  const [tempComments_Alex_N, setTempComments_Alex_N] = useState("");
  const [tempComments_Anton_C, setTempComments_Anton_C] = useState("");
  const [tempCampaignIDs, setTempCampaignIDs] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = React.useState("");

  const [selectedColumn, setSelectedColumn] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [selectedColumnAmount, setSelectedColumnAmount] = useState("amount");
  const [minValueAmount, setMinValueAmount] = useState(""); // New state for min value
  const [maxValueAmount, setMaxValueAmount] = useState(""); // New state for max value
  const [selectedColumnDR, setSelectedColumnDR] = useState("days_running");
  const [minValueDR, setMinValueDR] = useState(""); // New state for min value
  const [maxValueDR, setMaxValueDR] = useState(""); // New state for max value

  const [startDateStartedOn, setStartDateStartedOn] = useState(""); // Состояние для начальной даты
  const [endDateStartedOn, setEndDateStartedOn] = useState(""); // Состояние для конечной даты

  const [selectedColumnFBID, setSelectedColumnFBID] = useState("fb_library_id");
  const [searchTermFBID, setSearchTermFBID] = useState("");

  const [selectedColumnSearchUrl, setSelectedColumnSearchUrl] = useState("search_url");
  const [searchTermSearchUrl, setSearchTermSearchUrl] = useState("");

  const [selectedColumnParseUrl, setSelectedColumnParseUrl] = useState("parse_url");
  const [searchTermParseUrl, setSearchTermParseUrl] = useState("");

  const [selectedColumnCampIDS, setSelectedColumnCampIDS] = useState("campaign_ids");
  const [searchTermCampIDS, setSearchTermCampIDS] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/selected_categories_analysis/"
        );
        const rows = response.data.map((row) => ({
          ...row,
        }));
        setTableData((prevState) => ({ ...prevState, rows }));
        setFilteredData(rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleStatusChange = (row, column) => async (event) => {
    const updatedValue = event.target.value;

    const updatedData = {
      fb_library_id: row.fb_library_id,
      Artem_Y: row.Artem_Y,
      Vlad_A: row.Vlad_A,
      Anton_C: row.Anton_C,
      Alex_N: row.Alex_N,
      Vlad_A_comment: row.Vlad_A_comment,
      Artem_Y_comment: row.Artem_Y_comment,
      Alex_N_comment: row.Alex_N_comment,
      Anton_C_comment: row.Anton_C_comment,
      CampaignIDs: row.CampaignIDs,
      parse_date_t: row.parse_date_t,
      [column]: updatedValue,
      user_email: userEmail,
    };

    // Оптимистичное обновление состояния
    setTableData((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((r) =>
        r.fb_library_id === row.fb_library_id && r.parse_date_t === row.parse_date_t
          ? { ...r, [column]: updatedValue }
          : r
      ),
    }));

    try {
      await axios.post(
        "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/selected_categories_analysis_update/",
        updatedData
      );
      toast.success("Selected categories updated successfully!");
    } catch (error) {
      console.error("Error updating data:", error.response.data);
      toast.error("Failed to update selected categories!");

      setTableData((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((r) =>
          r.fb_library_id === row.fb_library_id && r.parse_date_t === row.parse_date_t
            ? { ...r, [column]: row[column] } // Восстановление старого значения
            : r
        ),
      }));
    }
  };

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
    setTempComments_Artem_Y(row.Artem_Y_comment);
    setTempComments_Vlad_A(row.Vlad_A_comment);
    setTempComments_Alex_N(row.Alex_N_comment);
    setTempComments_Anton_C(row.Anton_C_comment);
    setTempCampaignIDs(row.CampaignIDs);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);
    setTempComments_Artem_Y("");
    setTempComments_Vlad_A("");
    setTempComments_Alex_N("");
    setTempComments_Anton_C("");
    setTempCampaignIDs("");
  };

  const handleCommentSave = async () => {
    if (!selectedRow) return;

    const updatedData = {
      fb_library_id: selectedRow.fb_library_id,
      Vlad_A_comment: tempComments_Vlad_A,
      Artem_Y_comment: tempComments_Artem_Y,
      Alex_N_comment: tempComments_Alex_N,
      Anton_C_comment: tempComments_Anton_C,
      CampaignIDs: tempCampaignIDs,
      parse_date_t: selectedRow.parse_date_t,
      user_email: userEmail,
    };

    try {
      await axios.post(
        "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/selected_categories_analysis_update/",
        updatedData
      );

      const updatedRows = tableData.rows.map((r) =>
        r.fb_library_id === selectedRow.fb_library_id && r.parse_date_t === selectedRow.parse_date_t
          ? {
              ...r,
              Vlad_A_comment: tempComments_Vlad_A,
              Artem_Y_comment: tempComments_Artem_Y,
              Alex_N_comment: tempComments_Alex_N,
              Anton_C_comment: tempComments_Anton_C,
              CampaignIDs: tempCampaignIDs,
            }
          : r
      );

      setTableData((prevState) => ({
        ...prevState,
        rows: updatedRows,
      }));

      toast.success("Comments and Campaign IDs updated successfully!");
    } catch (error) {
      console.error("Error updating data:", error.response.data);
      toast.error("Failed to update Comments and Campaign IDs!");
    }

    handleEditDialogClose();
  };

  const renderSelect = (row, column) => {
    const currentValue = row[column];
    const borderColor =
      currentValue === "Launched"
        ? "green"
        : currentValue === "Preparing"
        ? "yellow"
        : currentValue === "Not Used"
        ? "red"
        : currentValue === "Whitelisted"
        ? "blue"
        : "purple";
    const backgroundColor =
      borderColor === "green"
        ? "rgba(5,250,5,0.2)"
        : borderColor === "yellow"
        ? "rgba(248,248,2,0.2)"
        : borderColor === "red"
        ? "rgba(255, 0, 0, 0.2)"
        : borderColor === "blue"
        ? "rgba(1,214,239,0.2)"
        : "rgba(86,0,255,0.2)";

    return (
      <Select
        value={currentValue}
        onChange={handleStatusChange(row, column)}
        sx={{
          color: borderColor,
          border: `1px solid ${borderColor}`,
          borderRadius: "4px",
          backgroundColor: backgroundColor,
          "&:hover": {
            backgroundColor: backgroundColor,
          },
        }}
        variant="outlined"
      >
        {statuses.map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const memoizedRows = useMemo(() => {
    // Функция для форматирования даты
    const formatDate = (dateStr) => {
      if (!dateStr) return new Date();
      const [day, month, year] = dateStr.split(".");
      const formattedDateStr = `${year}-${month}-${day}`;
      const date = new Date(formattedDateStr);
      if (isNaN(date.getTime())) {
        console.warn(`Invalid date format: ${dateStr}`);
        return new Date();
      }
      return date;
    };

    // Фильтрация данных
    const filteredRows = tableData.rows.filter((row) => {
      // Status filter
      if (selectedStatus) {
        if (row[selectedColumn] !== selectedStatus) return false;
      }

      // Search filter
      const searchMatch = Object.values(row).some((val) =>
        String(val).toLowerCase().includes(search.toLowerCase())
      );

      // Amount range filter
      let columnFilterMatch = true;
      if (selectedColumnAmount && minValueAmount && maxValueAmount) {
        const value = parseFloat(row[selectedColumnAmount]);
        columnFilterMatch =
          value >= parseFloat(minValueAmount) && value <= parseFloat(maxValueAmount);
      }

      // Days Running range filter
      if (selectedColumnDR && minValueDR && maxValueDR) {
        const value = parseFloat(row[selectedColumnDR]);
        columnFilterMatch = value >= parseFloat(minValueDR) && value <= parseFloat(maxValueDR);
      }

      // Date range filter
      const rowDate = formatDate(row.Date);
      if (startDateStartedOn && endDateStartedOn) {
        const start = formatDate(startDateStartedOn);
        const end = formatDate(endDateStartedOn);
        if (rowDate < start || rowDate > end) return false;
      }

      // FBID, Search URL, Parse URL, Camp IDS filters
      const fbIdMatch =
        !searchTermFBID ||
        String(row[selectedColumnFBID] || "")
          .toLowerCase()
          .includes(searchTermFBID.toLowerCase());
      const searchUrlMatch =
        !searchTermSearchUrl ||
        String(row[selectedColumnSearchUrl] || "")
          .toLowerCase()
          .includes(searchTermSearchUrl.toLowerCase());
      const parseUrlMatch =
        !searchTermParseUrl ||
        String(row[selectedColumnParseUrl] || "")
          .toLowerCase()
          .includes(searchTermParseUrl.toLowerCase());
      const campIdMatch =
        !searchTermCampIDS ||
        String(row[selectedColumnCampIDS] || "")
          .toLowerCase()
          .includes(searchTermCampIDS.toLowerCase());

      return (
        searchMatch &&
        columnFilterMatch &&
        fbIdMatch &&
        searchUrlMatch &&
        parseUrlMatch &&
        campIdMatch
      );
    });
    // Преобразование данных
    return filteredRows.map((row) => ({
      ...row,
      Artem_Y: renderSelect(row, "Artem_Y"),
      Vlad_A: renderSelect(row, "Vlad_A"),
      Anton_C: renderSelect(row, "Anton_C"),
      Alex_N: renderSelect(row, "Alex_N"),
      edit: (
        <IconButton onClick={() => handleEditClick(row)}>
          <EditIcon />
        </IconButton>
      ),
    }));
  }, [
    tableData.rows,
    search,
    selectedColumn,
    selectedStatus,
    minValueAmount,
    maxValueAmount,
    selectedColumnAmount,
    minValueDR,
    maxValueDR,
    selectedColumnDR,
    startDateStartedOn,
    endDateStartedOn,
    searchTermFBID,
    searchTermSearchUrl,
    searchTermParseUrl,
    searchTermCampIDS,
    handleEditClick,
    renderSelect,
  ]);

  useEffect(() => {
    setFilteredData(
      tableData.rows.filter((row) =>
        Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
      )
    );
  }, [search, tableData.rows]);

  const handleColumnChangeAmount = (value) => {
    setSelectedColumnAmount(value);
  };

  const handleMinValueAmountChange = (value) => {
    setMinValueAmount(value);
  };

  const handleMaxValueAmountChange = (value) => {
    setMaxValueAmount(value);
  };

  const handleColumnChangeDR = (value) => {
    setSelectedColumnDR(value);
  };

  const handleMinValueDRChange = (value) => {
    setMinValueDR(value);
  };

  const handleMaxValueDRChange = (value) => {
    setMaxValueDR(value);
  };

  const handleSearchTermChangeFBID = (event) => {
    setSearchTermFBID(event.target.value);
  };

  const handleSearchTermChangeSearchUrl = (event) => {
    setSearchTermSearchUrl(event.target.value);
  };

  const handleSearchTermChangeParseUrl = (event) => {
    setSearchTermParseUrl(event.target.value);
  };

  const handleSearchTermChangeCampIDS = (event) => {
    setSearchTermCampIDS(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Box display="flex" justifyContent="center" width="100%">
              <FilterByColumnNameMinMax
                onColumnChange={handleColumnChangeAmount}
                onMinValueChange={handleMinValueAmountChange}
                onMaxValueChange={handleMaxValueAmountChange}
                minValue={minValueAmount}
                maxValue={maxValueAmount}
                columnName={"amount"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box display="flex" justifyContent="center" width="100%">
              <FilterByColumnNameMinMax
                onColumnChange={handleColumnChangeDR}
                onMinValueChange={handleMinValueDRChange}
                onMaxValueChange={handleMaxValueDRChange}
                minValue={minValueDR}
                maxValue={maxValueDR}
                columnName={"days_running"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box display="flex" justifyContent="center" width="100%">
              <FilterByColumnNameSearch
                columnName={selectedColumnFBID}
                searchTerm={searchTermFBID}
                onSearchTermChange={handleSearchTermChangeFBID}
                columnLabel={"fb_library_id"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box display="flex" justifyContent="center" width="100%">
              <FilterByColumnNameSearch
                columnName={selectedColumnSearchUrl}
                searchTerm={searchTermSearchUrl}
                onSearchTermChange={handleSearchTermChangeSearchUrl}
                columnLabel={"search_url"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box display="flex" justifyContent="center" width="100%">
              <FilterByColumnNameSearch
                columnName={selectedColumnParseUrl}
                searchTerm={searchTermParseUrl}
                onSearchTermChange={handleSearchTermChangeParseUrl}
                columnLabel={"parse_url"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box display="flex" justifyContent="center" width="100%">
              <FilterByColumnNameSearch
                columnName={selectedColumnCampIDS}
                searchTerm={searchTermCampIDS}
                onSearchTermChange={handleSearchTermChangeCampIDS}
                columnLabel={"campaign_ids"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent="center" width="100%">
              <FilterCard
                selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                statuses={statuses}
                md_names={md_names}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box display="flex" justifyContent="center" width="100%">
              <SearchFieldAll search={search} onSearchChange={handleSearchChange} />
            </Box>
          </Grid>
        </Grid>
        <DataTable table={{ ...tableData, rows: memoizedRows }} entriesPerPage={false} />
      </MDBox>
      <Dialog open={openEditDialog} onClose={handleEditDialogClose}>
        <DialogTitle>Edit Comments (Choose needed column)</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <MDInput
            label="Campaign IDs"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={tempCampaignIDs}
            onChange={(e) => setTempCampaignIDs(e.target.value)}
            sx={{ mb: 2, mt: 2 }}
          />
          <MDInput
            label="Artem_Y"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={tempComments_Artem_Y}
            onChange={(e) => setTempComments_Artem_Y(e.target.value)}
            sx={{ mb: 2 }}
          />
          <MDInput
            label="Alex_N"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={tempComments_Alex_N}
            onChange={(e) => setTempComments_Alex_N(e.target.value)}
            sx={{ mb: 2 }}
          />
          <MDInput
            label="Anton_C"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={tempComments_Anton_C}
            onChange={(e) => setTempComments_Anton_C(e.target.value)}
            sx={{ mb: 2 }}
          />
          <MDInput
            label="Vlad_A"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={tempComments_Vlad_A}
            onChange={(e) => setTempComments_Vlad_A(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center", gap: "16px" }}>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCommentSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default SelectedCategoriesAnalysis;
