import React, { useState } from "react";
import moment from "moment-timezone";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDDatePicker from "components/MDDatePicker";
import "./styles.css";
import MDTypography from "../../../components/MDTypography";

function CreateReport() {
  const API_URL = "https://reports-maker-tmgx45bhsa-uc.a.run.app";
  const [date, setDate] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const handleReportRequest = (endpoint) => {
    const url = new URL(API_URL + endpoint);
    if (date) {
      const currentDate = new Date(date);
      currentDate.setDate(currentDate.getDate() + 1); // idk how to change timezone
      const isoDate = currentDate.toISOString().split("T")[0];
      url.searchParams.append("date", isoDate);
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      });
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button
            className="button-list-button"
            onClick={() => handleReportRequest("/get_s1_report_intra_dashboard/")}
          >
            Get S1 report
          </button>
          <button
            className="button-list-button"
            onClick={() => handleReportRequest("/get_tonic_report_intra_dashboard/")}
          >
            Get Tonic report
          </button>
          <button
            className="button-list-button"
            onClick={() => handleReportRequest("/update_da_raw_data_intra_dashboard/")}
          >
            Get DA report
          </button>
        </div>
      </div>
    );
  };

  const ResponseMessage = () => {
    return (
      <div className="response-message-container">
        {responseMessage && (
          <Card className="response-message">
            <pre>{responseMessage}</pre>
          </Card>
        )}
      </div>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <MDBox display="flex" alignItems="center" justifyContent="center" mb={3}>
              <MDTypography variant="h6" color="textSecondary" mr={2}>
                Choose a date (default is today):
              </MDTypography>
              <MDDatePicker
                input={{ placeholder: "YYYY-MM-DD" }}
                onChange={(date) => setDate(date)}
              />
            </MDBox>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Reports</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2}>
                <ButtonList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateReport;
